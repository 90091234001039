/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { LunchTipsDetailUrl, AddRatingUrl } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import MiniMap from '../Locations/LocationOnMap';
import { useHistory, useLocation } from 'react-router-dom';
import Slider from "../../Utilities/Slider/Slider";
import Rating from '@material-ui/lab/Rating';
import Alert from 'react-bootstrap/Alert';
import { t } from '../../Translation/Translation';
import LazyLoad from 'react-lazyload';
import '../../Static/css/common.css';
import FullScreenImage from "../../Utilities/FullScreenImage/FullScreenImage";
import Popup from "../../Utilities/Popup/modelPopup";
import BackButtonComponent from '../../Utilities/BackButtonComponent';
import previewFn from '../../Common/Preview';
import { useParams } from "react-router";
import BackButton from "../../Utilities/BackButton";

function LunchTipsDetails({ updatesEntityId, backButton }) {
  const location = useLocation();
  const [longitude, setLong] = useState(null);
  const [latitude, setLat] = useState(null);
  const [address, setAddress] = useState("");
  const [title, setTitle] = useState("");
  const [tipLocation, setTipLocation] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [venue, setVenue] = useState("");
  const [pincode, setPincode] = useState("");
  const [rating, setRating] = useState("");
  const [ratingStatus, setRatingStatus] = useState(0)
  const [value, setValue] = useState(0);
  const [hover, setHover] = useState(-1);
  const [weburl, setWeburl] = useState("");
  const [entryId, setEntryId] = useState()
  const [message, setMessage] = useState('');
  const [lunchtipdata, setLuncTipData] = useState([]);
  const [description, setDescription] = useState('')
  const [sliderData, setsliderData] = useState([]);
  const [types, setTypes] = useState([]);
  const [mapaddress, setMapAddress] = useState();
  const [popupMessage, setPopupMessage] = useState("");
  const [locationIds, setLocationIds] = useState([]);

  const history = useHistory();
  const [fullscreenState, setfullscreenState] = useState(false);
  const [imageSrc, setimageSrc] = useState("");
  const [BannerTime, setBannerTime] = useState();
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  const [previewBack, setpreviewBack] = useState(false);
  let params = useParams();

  useEffect(() => {
    let currentpath = window.location.href;
    if (currentpath.indexOf("preview") > 0){
      setpreviewBack(true);
    }
  }, [])

  useEffect(() => {
    if (urlState == false && updatesEntityId !== undefined){
      setbackbuttonUrl('/lunchtips/' + updatesEntityId);
      seturlState(true);
    }
  }, [updatesEntityId])

  useEffect(() => {
    setEntryId(updatesEntityId)
    DisableFullScreenImage()
    var get_id = localStorage.getItem('id');
    let param = previewFn(params);
    //api call for lunchtip details
    let data = {
      'user_id': get_id,
      'type': 'view',
      'id': updatesEntityId,
      'param': param,
    }
    APICALL.service(LunchTipsDetailUrl, "POST", data)
      .then(result => {
        const sliders = [];
        result[updatesEntityId].banner_images.forEach((val, i) => {
          sliders[i] = { 'original': val.image, 'thumbnail': val.image };
        })
        setLuncTipData(result);
        setMapAddress(result[updatesEntityId].map_details)
        setBannerTime(result[updatesEntityId].banner_time)

        setLong(result[updatesEntityId].map_longitude)
        setLat(result[updatesEntityId].map_latitude)
        setTitle(result[updatesEntityId].title)
        setCountry(result[updatesEntityId].country)
        setState(result[updatesEntityId].state_region)
        setVenue(result[updatesEntityId].venue)
        setPincode(result[updatesEntityId].postcode)
        setTipLocation(result[updatesEntityId].location)
        if (result[updatesEntityId].type.length !== 0) {
          setTypes(result[updatesEntityId].type.slice(0, result[updatesEntityId].type.length).join(', '))
        }
        setWeburl(result[updatesEntityId].website_url)
        if (result[updatesEntityId].rating !== null) {
          var rate = result[updatesEntityId].rating.includes('.') ? result[updatesEntityId].rating : result[updatesEntityId].rating + ".0"
        }
        setRating(rate)
        setRatingStatus(result[updatesEntityId].rating_status)
        setAddress(result[updatesEntityId].compelete_address)
        setDescription(result[updatesEntityId].description)
        setsliderData([])
        setsliderData(sliders);
      })
      .catch(error => {
        setLuncTipData([]);
        setsliderData([]);
      })
  }, [updatesEntityId]);

  // back button funtionality 
  function historyBack() {
    let response = BackButton("/lunchtips");
    if (response === false) {
      backButton(false);
      history.push('/lunchtips');
    }
    else {
      history.push(response);
    }
  }

  //api call to add ratings
  const addRating = () => {
    var get_id = localStorage.getItem('id');
    if (value !== 0) {
      let data = {
        'entry_id': entryId,
        'user_id': get_id,
        'rating': value
      }
      APICALL.service(AddRatingUrl, "POST", data)
        .then(result => {
          setMessage(result.message);
        })
        .catch(error => {
          setMessage("Error in adding ratings");
        })
    } else {
      setMessage("Please select star to save");
    }
  }

  //To return HTML from string containing HTML tags
  function createMarkup(data) {
    if(data){
      data = data.replaceAll("<p></p>", "<br />");
    }
    return { __html: data };
  }

  //check permissions and show popup for alerts
  function errorGetcoordinates() {
    if (
      navigator.platform.indexOf("iPhone") !== -1 ||
      navigator.platform.indexOf("iPod") !== -1 ||
      navigator.platform.indexOf("iPad") !== -1
    ) {
      setPopupMessage(
        t("Provide access in") + ":" + t("Settings") + ">" + t("Privacy") + ">" + t("Location Services") + ">" + t("Safari")
      );
    } else {
      setPopupMessage(t("Error getting the user location, please check device permission") + ".");
    }
  }

  //function for showing root map
  function getLocation() {
    navigator.geolocation.getCurrentPosition(
      getcoordinates,
      errorGetcoordinates
    );
  }

  //check coordinates for android and ios
  function getcoordinates(position) {

    if ((navigator.platform.indexOf("iPhone") !== -1)
      || (navigator.platform.indexOf("iPod") !== -1)
      || (navigator.platform.indexOf("iPad") !== -1)) {
      var url = 'https://www.google.com/maps/dir/' + position.coords.latitude + ',' + position.coords.longitude + '/' + latitude + ',' + longitude;
      window.location.assign(url, "_blank");
    }
    else {
      var urls = 'https://www.google.com/maps/dir/' + position.coords.latitude + ',' + position.coords.longitude + '/' + latitude + ',' + longitude;
      window.open(urls);
    }

  }

  //close popup function 
  const closeAlertPopup = () => {
    setPopupMessage("");
  };

  //enable and disable full screen image functions
  function EnableFullScreenImage(ImageData) {
    setimageSrc(ImageData);
    setfullscreenState(true);
  }
  function DisableFullScreenImage() {
    setimageSrc("");
    setfullscreenState(false);
  }

  return (
    fullscreenState ? <FullScreenImage imageSrc={imageSrc} DisableFullScreenImage={DisableFullScreenImage}/> :
    <div className="container-md customContainer">
      <div className="mb-5">
        <div className="justify-content-md-center rounded mb-5">
          {localStorage.getItem('DeviceMode') !== "Desktop" ? 
            <div className="col-md-12  px-0 col-12 mt-4">
            <div className=" row">
              {/* <div className="col-2"> <p className="browserback" onClick={history.goBack}>back</p></div> */}
              <div className="col-2">
                {previewBack ? null : <p className="browserback" onClick={historyBack}>back</p>}
                </div>
                    {backbuttonUrl && <BackButtonComponent backButtonLink = {backbuttonUrl}/>}

                  <h2 className="col-8 textcolor1 pagetitles text-center">{t("Lunch tip")}</h2>
                </div>
              </div> : null
            }
            <Popup
              show={popupMessage !== "" ? true : false}
              onHide={closeAlertPopup}
              size={"lg"}
              title={"Alert"}
              body={popupMessage}
            />
            {lunchtipdata === undefined && <div className="col text-center" ><h4> {t("Loading details.")}</h4> </div>}
            <LazyLoad>
              {(sliderData.length > 0) && <Slider history={history} bannerTime={BannerTime ? BannerTime : '5'} sliderData={sliderData} EnableFullScreenImage={EnableFullScreenImage} />}
            </LazyLoad>
            {lunchtipdata !== undefined &&
              <div className="">
                <div className="col-12  ">
                  <div className="row border-bottom pt-2 pb-2">
                    <div className="col-10 pl-2 ">
                      <h5 className="mb-2 ">{title}</h5>
                      {/* <h5 className= "mb-2" >{tipLocation}</h5> */}
                    </div>
                    <div className="col-2 pr-2">
                      {rating !== null && <p className="" ><p className="textcolor1 mb-0  rateAlign tab-ratealign"><span className="star detailstar"></span>{rating}</p></p>}
                    </div>
                    {types !== [] && <div className="col-12 row px-0 m-auto">
                      <p className="mb-0 col-8 px-0 mt-2 pl-2 d-flex align-items-center"><span className="foodType imgSprite inner-foodtype"></span><h6 className="font-weight-normal mb-0 ml-2">{types ? types : null}</h6></p>
                      <p className="m-0 col-4 text-right pr-2 weburl" > <a href={weburl} className={weburl != null ? "textcolor1 websiteurl" : "disableLink websiteurl"}>{t("Visit website")}</a></p>
                    </div>}

                  </div>
                </div>
                {latitude != null && <div className="map-border-bottom">{latitude != null ? <MiniMap lats={latitude} long={longitude} mapaddress={mapaddress} zoomLevel={17} type="lunchtipdetail" /> : null}</div>}
                <div className="col-12 pl-3">
                  <div className="row  py-3">
                    <div className="col-10 pl-0">
                      <p className="locationRed imgSprite detaillocation"></p>
                      <p className="mb-0 ml-4 pl-2" >{address} <br /> {pincode} {venue} <br /> {state} <br /> {country}</p>
                    </div>
                    <div className="col-2 pr-0 detailgetthere">
                      <div className="float-right"><span className={latitude != null ? "textcolor1 pt-4 pr-2 float-right customCursor" : "disableLink  pt-4 pr-2 float-right"} onClick={getLocation}>{t('Go!')}</span></div>
                    </div>
                  </div>
                </div>

                <div className="word-break mt-3 Richtexterditor" dangerouslySetInnerHTML={createMarkup(description)} />
                {ratingStatus === 1 && <div className="col-12 mt-2 px-0 py-2 ratingSection">
                  <h6 className="my-2 textcolor1 ">{t("Rate")}</h6>
                  <div className="rate-center-align">
                    <Rating className=" itemrate rateSection"
                      name="hover-feedback"
                      value={value}
                      precision={0.5}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      size="large"
                      onChangeActive={(event, newHover) => {
                        setHover(newHover);
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-12 text-center">
                    {message.length > 0 && (
                      <Alert className="textcolor1" onClose={() => setMessage("")} dismissible>
                        <p className="rating-message">{t(message)}</p>
                      </Alert>
                    )}
                  </div>
                  <br></br>
                  <div className="col-md-12 col-12 pl-0 px-0 text-center votePopup mt-3">
                    <button className="btn w-25 m-0 rateSave  bg-color font-weight-bold" onClick={addRating} >{t("Save")}</button>
                  </div>
                </div>}
                <br></br>
              </div>
            }
          </div></div></div>
  )
}



export default LunchTipsDetails
