import React from "react";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import defaultImg from './images/user.png';
import './whoiswho.css';
import '../../../src/Static/css/common.css';
import { t } from '../../Translation/Translation';
import InfiniteScroll from "react-infinite-scroll-component";


export default function WhoiswhoComponent({ issearchResult, userListData, fetchMoreData, hasMorevalue, WhoiswhoUserId, activeItem={} }) {

  /**
   * 'notfound' is using when no result was there
   * 'users' is used for userlist data when 'issearchResult' is not there
   */
  let notfound;
  let users = userListData;
  try {
    if (issearchResult !== undefined) {
      if (issearchResult.length === 0) {
        notfound = 1;
      }
      else {
        users = issearchResult;
      }
    }
  } catch (e) {
    console.error(e);
  }

  let count = 0;
  Object.keys(users).map((data, id) => (
    count = count + 1
  ))
  // setting userid based on click function
  const UserId = (e) => {
    WhoiswhoUserId(e)
  }
  // const [hasMoreValue, sethasMoreValue] = useState(hasMore)

/**
 * Whoiswho div components
 */ let phone, mail;
  const Post = ({ id, profile_photo, firstName, lastName, userJobFunction, location, workEmail, workPhoneNumber, personalPhoneNumber, sensitiveInfo, user_role, personalEmail, p }) => (
    users &&
    <div className={(id == activeItem) ? "card mb-2 who-card activeItem" : "card mb-2 who-card"}>
      {/* <Lazyload> */}
      <div className="row no-gutters mb-2" >
        <div className="col-3 imagesection">
          {/* <Link to={("/whoiswhoDetails/" + id)}> <img className={((profile_photo === null) || (profile_photo === undefined) || (profile_photo === "")) ? "user-img rounded-circle" : "hide-class"} src={defaultImg} alt="user-img" ></img></Link> */}
          {/* <a onClick={() => UserId(id)} > <img className={((profile_photo === null) || (profile_photo === undefined) || (profile_photo === "")) ? "user-img rounded-circle" : "hide-class"} src={defaultImg} alt="user-img" ></img></a> */}
          {/* <Link to={("/whoiswhoDetails/" + id)}> <img className={((profile_photo === null) || (profile_photo === undefined) || (profile_photo === "")) ? "hide-class" : "user-img rounded-circle"} src={profile_photo} alt="user-img" ></img></Link> */}
          <a onClick={() => UserId(id)} ><img className="user-img rounded-circle" src={!((profile_photo === null) || (profile_photo === undefined) || (profile_photo === "")) ? profile_photo :  defaultImg} alt="user-img" /></a>

        </div>
        <div className="col-6 pl-2">
          {/* <Link to={("/whoiswhoDetails/" + id)}><h4 className="innerpagetitle mb-2 ml-0">{lastName} {firstName}</h4></Link> */}
          <a onClick={() => UserId(id)} ><h4 className="innerpagetitle mb-2 ml-0">{lastName} {firstName}</h4></a>
          <p className="descsize mb-0"><WorkOutlineOutlinedIcon /> <span className="pl-2">{userJobFunction}</span></p>
          <p className="descsize mb-0"><LocationOnOutlinedIcon /><span className="pl-2"> {location}</span></p>
        </div>
        <div className="col-3 whoicons pr-0 pl-2 text-right">
          {mail = (((workEmail === null) || (workEmail === '')) ? personalEmail : workEmail), <a href={"mailto:" + mail} className={((workEmail === null) || (workEmail === "")) ? sensitiveInfo === true ? (((personalEmail === "") || (personalEmail === null)) ? "disable-class emaildisable" : "emailactive") : "disable-class emaildisable" : "emailactive"}><p className="wswicons  mb-0"></p></a>}
          {phone = ((workPhoneNumber === "") || (workPhoneNumber === null)) ? personalPhoneNumber : workPhoneNumber, (phone === null || phone === undefined) ? null : phone = phone.replace("+", ""), <a href={"https://api.whatsapp.com/send?phone=" + phone} className={((workPhoneNumber === ("")) || (workPhoneNumber === null)) ? sensitiveInfo === true ? (((personalPhoneNumber === "") || (personalPhoneNumber === null)) ? "disable-class whatsappdisable" : "whatsapactive") : "disable-class whatsappdisable" : "whatsapactive"} ><p className="wswicons mb-0"></p></a>}
          <a href={"tel:" + phone} className={((workPhoneNumber === ("")) || (workPhoneNumber === null)) ? sensitiveInfo === true ? (((personalPhoneNumber === "") || (personalPhoneNumber === null)) ? "disable-class disbalephone" : "textcolor1 activephone") : "disable-class disbalephone" : "textcolor1 activephone"} ><p className="wswicons mb-0"></p></a>
        </div>
      </div>
      {/* </Lazyload> */}
    </div>
  )
  return (
    <div>{users === undefined || users === '' ? <div className="col-12 text-center"><h5 className="textcolor1 text-center">{t('Loading users')}.</h5></div> : null}
      <div className="container customContainer">
        {
          notfound === 1 ?
            <div className="textcolor1 col text-center">
              {t('search not found')}
            </div> :
            <InfiniteScroll
              dataLength={count}
              next={fetchMoreData}
              hasMore={hasMorevalue}
              style={{ overflow: 'unset !important' }}
              loader={<h5 className="textcolor1 text-center">{t('Loading users')}.</h5>}
              scrollableTarget="scrollableDiv"
            >
              {users !== undefined &&
                Object.keys(users).map((data, id) => (
                  <Post key={id} {...users[data]} />
                ))}
            </InfiniteScroll>
        }
      </div>
    </div>
  );
}