/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./profile.css";
import ProfileImage from "../../ProfileImage/ProfileImage";
import { Userhobbies } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import { t } from '../../Translation/Translation';
import { Multiselect } from 'multiselect-react-dropdown';
import Select from 'react-select';
import ScrollToTop from '../../Utilities/ScrollToTop';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-input-2';

import makeAnimated from "react-select/animated";
import MultiSelect from "../../Utilities/MultiSelect.js";
import { components } from "react-select";


const Contact = ({
  onSubmit,
  updateField,
  form,
  updateHobbies,
  previousTab,
  changeavatar,
  selectedHobbies,
  selectedKnownLanguages,
  updateOtherLanguages,
  selectedMothertongue,
  updateMotherTongue,
  handleBirthdate,
  updatepersonalnumber,
  hideFunction
}) => {
  let resulting_data = [];
  if (
    form.userHobbies !== "" &&
    form.userHobbies !== undefined &&
    form.userHobbies != null
  ) {
    Object.keys(form.userHobbies).map(
      (key, i) => (resulting_data[i] = { value: form.userHobbies[key], label:form.userHobbies[key]})
    );
  }

  let otherLanguagesData = [];
  if (
    form.otherLanguages !== "" &&
    form.otherLanguages !== undefined &&
    form.otherLanguages != null
  ) {
    Object.keys(form.otherLanguages).map(
      (key, i) => (otherLanguagesData[i] = {value: form.otherLanguages[key], label: form.otherLanguages[key]})
    );
  }

  let motherTongueData = [];
  if (
    form.motherTongue !== "" &&
    form.motherTongue !== undefined &&
    form.motherTongue != null
  ) {
    Object.keys(form.motherTongue).map(
      (key, i) => (motherTongueData[i] = { value: form.motherTongue[key], label: form.motherTongue[key] })
    );
  } 

  const [hobbies, sethobbies] = useState([]);
  const [hobbiesList, sethobbiesList] = useState([]);
  const [selectedItem, setselectedItem] = useState([]);
  const [otherLanguages, setotherLanguages] = useState([]);
  const [defaultLanguages, setdefaultLanguages] = useState([]);
  const [otherLanguagesList, setotherLanguagesList] = useState([]);
  const [selectedOtherLanguages, setselectedOtherLanguages] = useState([]);
  const [mainLanguage, setmainLanguage] = useState([]);
  const [mainLanguageList, setmainLanguageList] = useState([]);
  const [selectedMainLanguage, setselectedMainLanguage] = useState([]);
  const [AvatarImageStatus, setAvatarImageStatus] = useState(false)
  // const [startDate, setStartDate] = useState(new Date());
  // const [startDate, setStartDate] = useState(new Date(form.birthDate));

  //Styling the multiselect component
  const multiselect_style = {
    chips: {
      background: "whitesmoke",
      color: "black",
    }
  };

  useEffect(() => {
    const get_id = localStorage.getItem("id");
    let data = {
      user_id: get_id,
    };
    APICALL.service(Userhobbies, "POST", data).then((result) => {
      const motherlanguage1 = [];
      setmainLanguageList(result.languages);
      Object.keys(result.languages).map((value, i) =>
        motherlanguage1.push({ value: result.languages[value], label: result.languages[value] })
      )
      motherlanguage1.sort(function (a, b) {
        var nameA = a.value.toUpperCase(); // ignore upper and lowercase
        var nameB = b.value.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      setmainLanguage(motherlanguage1);

      const languages1 = [];
      setotherLanguagesList(result.languages);
      Object.keys(result.languages).map((value, i) =>
        languages1.push({value : result.languages[value], label:result.languages[value]})
      )
      setotherLanguages(languages1.sort());
      setdefaultLanguages(languages1.sort());

      const hobbiesList1 = [];
      sethobbiesList(result.hobbies);
      Object.keys(result.hobbies).forEach((key) =>{
        if (resulting_data.includes({ value: result.hobbies[key], label: result.hobbies[key] }) === false){
          hobbiesList1.push({ value: result.hobbies[key], label: result.hobbies[key] })
        }
      });

      var mappedHobbies = hobbiesList1.map(function (el, i) {
        return { value: el.label, label: el.label };
      })
      mappedHobbies.sort(function (a, b) {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });
      var sortedHobbies = mappedHobbies.map(function (el) {
        return el;
      });
      
      sethobbies(sortedHobbies);
      // if (selectedHobbies.length > 0) {
      //   resulting_data = [];
      //   selectedHobbies.forEach((key, i) => {
      //     resulting_data[i] = {value:selectedHobbies[key], label:selectedHobbies[key]}; 
      //   });
      // }
      setselectedItem(resulting_data);

      if (selectedKnownLanguages.length > 0) {
        otherLanguagesData = [];
        selectedKnownLanguages.forEach((key, i) => {
          otherLanguagesData[i] = {value: result.languages[key], label: result.languages[key]};
        });
      }
      setselectedOtherLanguages(otherLanguagesData);

      if (selectedMothertongue.length > 0) {
        motherTongueData = [];
        selectedMothertongue.forEach((key, i) => {
          motherTongueData[i] = { value: result.languages[key], label: result.languages[key] };
        });
      }
      setselectedMainLanguage(motherTongueData);

      if (motherTongueData.length > 0) {
        var filtereddata = (languages1).filter(function (item) {
          return item.value !== motherTongueData[0].value
        })
        setotherLanguages(filtereddata);
      }

      if (otherLanguagesData.length > 0) {
        let intersection = languages1.filter(x => !otherLanguagesData.includes(x));
        let finalfiltering = [];
        intersection.forEach((key, i) => {
          finalfiltering[i] = key
        })
        finalfiltering.sort(function (a, b) {
          var nameA = a.value.toUpperCase(); // ignore upper and lowercase
          var nameB = b.value.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        setmainLanguage(finalfiltering);
      }
    });
  }, []);

  //for mainlanguage
  useEffect(() => {
    const selected_data_mainLanguage = [];
    Object.keys(selectedMainLanguage).map((key, i) =>
      selected_data_mainLanguage.push(getKey(selectedMainLanguage[key].value))
    );
    updateMotherTongue(selected_data_mainLanguage);
  }, [selectedMainLanguage]);

  function getKey(value) {
    return Object.keys(mainLanguageList).find((key) => mainLanguageList[key] === value);
  }


  //for otherlanguages
  useEffect(() => {
    const selected_data_otherLanguages = [];
    Object.keys(selectedOtherLanguages).map((key, i) =>
      selected_data_otherLanguages.push(getKeyUsingValue(selectedOtherLanguages[key].value))
    );
    updateOtherLanguages(selected_data_otherLanguages);
  }, [selectedOtherLanguages]);

  function getKeyUsingValue(value) {
    return Object.keys(otherLanguagesList).find((key) => otherLanguagesList[key] === value);
  }

  //for hobbies
  useEffect(() => {
    const selected_data = [];
    Object.keys(selectedItem).map((key, i) =>
      selected_data.push(getKeyByValue(selectedItem[key].value))
    );
    updateHobbies(selected_data);
  }, [selectedItem]);

  function getKeyByValue(value) {
    return Object.keys(hobbiesList).find((key) => hobbiesList[key] === value);
  }

  //when Profile image changed
  function onImageChange(ImageData) {
    changeavatar(ImageData);
  }

  //When main language changed
  function handleMainLangugeChange(data) {
    setselectedMainLanguage([data]);
    setotherLanguages(defaultLanguages);
    var filteredd = (defaultLanguages).filter(function (item) {
      return item.value !== data.value
    })
    setotherLanguages(filteredd);
  }

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const animatedComponents = makeAnimated();

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  //when any language selected in other languages known
  function handleSelectevent(e) {
    if (e) {
      let intersection3 = defaultLanguages.filter(x => !e.includes(x));
      let finalfiltering3 = [];
      intersection3.forEach((key, i) => {
        finalfiltering3[i] = key;
      })
      finalfiltering3.sort(function (a, b) {
        var nameA = a.value.toUpperCase(); // ignore upper and lowercase
        var nameB = b.value.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      setmainLanguage(finalfiltering3);
    }
  }

  //when any language removed in other languages known
  function handleRemoveevent(e) {
    let intersection2 = defaultLanguages.filter(x => !e.includes(x));
    let finalfiltering2 = [];
    intersection2.forEach((key, i) => {
      finalfiltering2[i] = key;
    })
    finalfiltering2.sort(function (a, b) {
      var nameA = a.value.toUpperCase(); // ignore upper and lowercase
      var nameB = b.value.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    setmainLanguage(finalfiltering2);
  }

  function updateFormData(date) {
    handleBirthdate(date);
  }
  function phonechange(phone) {

    updatepersonalnumber(phone.phone);
  }
  function AvatageImages () {
    setAvatarImageStatus(AvatarImageStatus ? false :true)
    hideFunction();
  }

  return (
    form && (
      <div id="first">
        <ScrollToTop />
        <div className="myform form ">
          <div className="logo mb-3"></div>
          <div className="mt-3">
            <ProfileImage
              image={form.profile_photo}
              onImageChange={onImageChange}
              AvatageImages = {AvatageImages}
              // imgvisibility = {imgvisibility}
            />
          </div>
          {AvatarImageStatus === false ? <div>
          <div className="text-center  mb-3 col-lg-12">
          <label className="textcolor1 font-weight-normal">{t('On this page you may complete your profile by providing more information about yourself. You can also choose which data you make visible to other BCrew users (check Settings).')} </label>
          </div>

          <div className="mobilediv">
          <form onSubmit={onSubmit} className="contactdetail col-md-12 col-12 px-0">
          <div className="form-group">
              <label className="textcolor1 sectionTitle ">
                {t("Private email address")}*
              </label>
              <input
                type = "email"
                name="personalEmail"
                className="form-control"
                placeholder={t("Personal email")}
                onChange={updateField}
                value={form.personalEmail}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              />
            </div>
            <div className="form-group">
              <label className="textcolor1 sectionTitle ">
                {t("Private phone number")}*
              </label>
              <PhoneInput
                country={"be"}
                onlyCountries={['be', 'nl', 'fr', 'de', 'es', 'ie', 'dk', 'se', 'no', 'lu', 'pl', 'it', 'gb', 'us', 'tr', 'ch', 'in']}
                value={form.personalPhoneNumber}
                onChange={(phone) => phonechange({ phone })}
                placeholder={t("Personal number")}
              />
            </div>
            <div className="form-group">
              <label className="textcolor1 sectionTitle ">{t('Birthday')}**</label>
              {/* {form.birthDate && <h5 className="innerpagetitle">{form.birthDate !== "" ? moment(form.birthDate).format('DD/MM/YYYY') : null}</h5>} */}
              <div className="date-pick-main-div">
                <DatePicker
                  className="form-control date-pick-block"
                  selected={((form.birthDate === null) || (form.birthDate === "")) ? null : new Date(form.birthDate)}
                  onChange={date => updateFormData(date)}
                  dateFormat="dd/MM/yyyy"
                  // peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  placeholderText={t("Select birthday")}
                  dropdownMode="select"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="textcolor1 sectionTitle ">
                {t("Hobbies")}
              </label>
              <MultiSelect
                options={hobbies}
                className = "lunchtip_foodtype"
                isMulti
                defaultValue = {resulting_data}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                hideSelectedOptions={true}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={(e) => {e ? setselectedItem(e) : setselectedItem([])}}
                allowSelectAll={false}
                value={selectedItem}
              />
              {/* <Multiselect
                options={hobbies}
                isObject={false}
                onSelect={(e) => setselectedItem(e)}
                onRemove={(e) => setselectedItem(e)}
                selectedValues={selectedItem}
                style={multiselect_style}
                placeholder={t("Select hobbies")}
              /> */}
            </div>
            <div className="form-group">
              <label className="textcolor1 sectionTitle ">
                {t("Fun facts")}
              </label>
              <textarea
                name="funFact"
                className="form-control"
                placeholder={t("tell us something interesting about yourself")}
                onChange={updateField}
                value={form.funFact === null ? "" : form.funFact}
              />
            </div>
            <div className="form-group">
              <label className="textcolor1 sectionTitle ">
                {t("Mother language")}
              </label>
              <Select
                className="lan-select"
                multi={false}
                options={mainLanguage}
                onChange={(e) => handleMainLangugeChange(e)}
                value={selectedMainLanguage}
                showNewOptionAtTop={false}
              // isClearable={true}
              />
            </div>
            
            <div className="form-group">
              <label className="textcolor1 sectionTitle ">
                {t("Other languages spoken")}
              </label>
              <MultiSelect
                options={otherLanguages}
                className = "lunchtip_foodtype"
                isMulti
                defaultValue = {selectedOtherLanguages}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                hideSelectedOptions={true}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={(e) => { e ? setselectedOtherLanguages(e): setselectedOtherLanguages([]); handleSelectevent(e)   }}
                allowSelectAll={false}
                value={selectedOtherLanguages}
                placeholder={t("Select other languages")}
              />
              {/* <Multiselect
                options={otherLanguages}
                isObject={false}
                onSelect={(e) => { setselectedOtherLanguages(e); handleSelectevent(e) }}
                onRemove={(e) => { setselectedOtherLanguages(e); handleRemoveevent(e) }}
                selectedValues={selectedOtherLanguages}
                style={multiselect_style}
                placeholder={t("Select other languages")}
                avoidHighlightFirstOption
              /> */}
            </div>
            <div className="text-left  mb-3 col-lg-12">
              <label className="textcolor1 small font-weight-normal">*{t('You may choose to make this information visible to other users(App Settings)')} </label>
              <label className="textcolor1 small font-weight-normal">**{t('This information will not be visible to other users')} </label>
            </div>
            

            <div className="col-md-12  px-0 mt-3 mb-5 contact-buttons">
              <button
                className="btn actionButton actionButton-left  mob-fontsize float-left"
                onClick={previousTab}
              >
                {t("Save")} & {t("Back")}
              </button>
              <button
                type="submit"
                className="btn mr-0  bg-color float-right actionButton mob-fontsize actionButton-right "
              >
                {t("Save")} & {t("Continue")}
              </button>
            </div>
          </form>
          </div>

          <div className="desktopdiv">
          <form onSubmit={onSubmit} className="contactdetail col-md-12 col-12">
            <div className="row">
            <div className="form-group col-lg-6 mb-3">
              <label className="textcolor1 sectionTitle ">
                {t("Private email address")}*
              </label>
              <input
                type="email"
                name="personalEmail"
                className="form-control"
                placeholder={t("Personal email")}
                onChange={updateField}
                value={form.personalEmail}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              />
            </div>
            <div className="form-group col-lg-6 mb-3">
              <label className="textcolor1 sectionTitle ">
                {t("Fun facts")}
              </label>
              <textarea
                name="funFact"
                className="form-control"
                placeholder={t("tell us something interesting about yourself")}
                onChange={updateField}
                value={form.funFact === null ? "" : form.funFact}
              />
            </div> 
            <div className="form-group col-lg-6 mb-3">
              <label className="textcolor1 sectionTitle ">
                {t("Private phone number")}*
              </label>
              <PhoneInput
                country={"be"}
                onlyCountries={['be', 'nl', 'fr', 'de', 'es', 'ie', 'dk', 'se', 'no', 'lu', 'pl', 'it', 'gb', 'us', 'tr', 'ch', 'in']}
                value={form.personalPhoneNumber}
                onChange={(phone) => phonechange({ phone })}
                placeholder={t("Personal number")}
              />
            </div>
            <div className="form-group col-lg-6 mb-3">
              <label className="textcolor1 sectionTitle ">
                {t("Mother language")}
              </label>
              <Select
                className="lan-select"
                multi={false}
                options={mainLanguage}
                onChange={(e) => handleMainLangugeChange(e)}
                value={selectedMainLanguage}
                showNewOptionAtTop={false}
              // isClearable={true}
              />
            </div>
            
            <div className="form-group col-lg-6 mb-3">
              <label className="textcolor1 sectionTitle ">{t('Birthday')}**</label>
              {/* {form.birthDate && <h5 className="innerpagetitle">{form.birthDate !== "" ? moment(form.birthDate).format('DD/MM/YYYY') : null}</h5>} */}
              <div className="date-pick-main-div">
                <DatePicker
                  className="form-control date-pick-block"
                  selected={((form.birthDate === null) || (form.birthDate === "")) ? null : new Date(form.birthDate)}
                  onChange={date => updateFormData(date)}
                  dateFormat="dd/MM/yyyy"
                  // peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  placeholderText={t("Select birthday")}
                  dropdownMode="select"
                />
              </div>
            </div>
            
            
            <div className="form-group col-lg-6 mb-3">
              <label className="textcolor1 sectionTitle ">
                {t("Other languages spoken")}
              </label>
              <MultiSelect
                options={otherLanguages}
                className = "lunchtip_foodtype"
                isMulti
                defaultValue = {selectedOtherLanguages}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                hideSelectedOptions={true}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={(e) => {e ? setselectedOtherLanguages(e) : setselectedOtherLanguages([]); handleSelectevent(e) }}
                allowSelectAll={false}
                value={selectedOtherLanguages}
                placeholder={t("Select other languages")}
              />
              {/* <Multiselect
                options={otherLanguages}
                isObject={false}
                onSelect={(e) => { setselectedOtherLanguages(e); handleSelectevent(e) }}
                onRemove={(e) => { setselectedOtherLanguages(e); handleRemoveevent(e) }}
                selectedValues={selectedOtherLanguages}
                style={multiselect_style}
                placeholder={t("Select other languages")}
                avoidHighlightFirstOption
              /> */}
            </div>

            <div className="form-group col-lg-6 mb-3">
              <label className="textcolor1 sectionTitle ">
                {t("Hobbies")}
              </label>
              <MultiSelect
                options={hobbies}
                className = "lunchtip_foodtype"
                isMulti
                defaultValue = {resulting_data}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                hideSelectedOptions={true}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={(e) => {e ? setselectedItem(e) : setselectedItem([])}}
                allowSelectAll={false}
                value={selectedItem}
                placeholder={t("Select hobbies")}
              />
              {/* <Multiselect
                options={hobbies}
                isObject={false}
                onSelect={(e) => setselectedItem(e)}
                onRemove={(e) => setselectedItem(e)}
                selectedValues={selectedItem}
                style={multiselect_style}    
                placeholder={t("Select hobbies")}
              /> */}
            </div> 
            <div className="text-left  mb-3 col-lg-12">
            <p className="col-md-12 col-lg-12 mb-0 px-0"><label className="textcolor1 small font-weight-normal">*{t('You may choose to make this information visible to other users(App Settings)')} </label></p>
            <p className="col-md-12 col-lg-12 mb-0 px-0"><label className="textcolor1 small font-weight-normal">**{t('This information will not be visible to other users')} </label></p>
            </div>
            
            <div className="col-md-12 ml-auto contact-buttons d-flex justify-content-center">
              <button
                className="btn actionButton desktopactionButton-left   float-left"
                onClick={previousTab}
              >
                {t("Save")} & {t("Back")}
              </button>
              <button
                type="submit"
                className="btn  bg-color float-right actionButton desktopactionButton-right"
              >
                {t("Save")} & {t("Continue")}
              </button>
            </div>
            </div>
          </form>

          </div>
          </div> :null }

        </div>
      </div>
    )
  );
};

export default Contact
