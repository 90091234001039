import React, { useEffect, useRef } from "react";
import logo from "../../Static/images/Bleckmen_logo.png";
import "../../Static/css/common.css";
import ToastMessage from "../../Utilities/ToastMessage";
import { useAppContext } from "../../Config/contextLib";


const LoginSelection = ({ formTypeSubmitCallback }) => {
  const showWarningDesktop = useRef(false);
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  useEffect(() => {
      // if (window.matchMedia("(display-mode: standalone)").matches) {
      //   console.log("display-mode is standalone");
      // } else {
      //   console.log("display-mode is not standalone");
      //   showWarningDesktop.current = true;
      // }
    
    // console.log("browser : ", navigator.userAgent);
    
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // true for mobile device
      console.log("mobile device");
    } else {
      // false for not mobile device
      //document.write("not mobile device");
      showWarningDesktop.current = true;
    }
  }, []);

  const stateId = (isAuthenticated ? 1 : 0);
  return (
    <div>
      <div className="col-md-12 text-center mt-3 logosection textcolor1">
        <img className="img-fluid logo" src={logo} alt="logo" />
      </div>
      <div className="bottom-buttons selectpage">
        {/* <p className="text-center">Login with</p> */}
        <div className="btn  col-12 bg-color mybtn text-center block mb-2">
          <a
            className="text-white"
            href={process.env.REACT_APP_BACKEND_URL + "sso?rid=1&state=" + stateId}
          >
            {" "}
            Login with Bleckmann Account{" "}
          </a>
        </div>
        {/* <div className="col-12 mybtn text-center block mb-2 mt-2">
          <span
            className="textcolor customCursor loginSelect"
            onClick={() => formTypeSubmitCallback("email")}
          >
            {" "}
            Login with Email{" "}
          </span>
        </div>
        <div className="col-12 mybtn text-center block mb-2">
          <span
            className="textcolor customCursor loginSelect"
            onClick={() => formTypeSubmitCallback("mobile")}
          >
            {" "}
            Login with Phone number{" "}
          </span>
        </div> */}
      </div>
      {/* <ToastMessage
        show={showWarningDesktop.current}
        message={"For the best user experience, we advise you to use a mobile device. Enjoy the BCrew App!"}
      /> */}
    </div>
  );
};

export default LoginSelection;
