import React, { useState } from "react";
import "./profile_image.css";
import 'antd/dist/antd.css';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import ImageAvatar from "./ImageAvatar";
import { Modal, Button } from 'antd';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import Avatar_dummy from "../Static/images/Avatar_dummy.jpeg";
import '../Static/css/common.css';
import { t } from './../Translation/Translation';
import Avatars from "../Utilities/AvatarImages/avatar";


export default function ProfileImage({ image, onImageChange,AvatageImages, imgvisibility }) {


  const [profileImage, setprofileImage] = useState(image);

  const [visible, setvisible] = useState(false);
  const[AvatarImage, setAvatarImage] =useState(false)
  const [data_inside_model, setdata_inside_model] = useState();
  const [Attributes, setAttributes] = useState({
    topType: "ShortHairDreads02",
    accessoriesType: "Prescription02",
    hairColor: "BrownDark",
    facialHairType: "Blank",
    clotheType: "Hoodie",
    clotheColor: "PastelBlue",
    eyeType: "Happy",
    eyebrowType: "Default",
    mouthType: "Smile",
    avatarStyle: "Transparent",
    skinColor: "Light",
  });


  const handleImageChange = (profileImage) => {
    setprofileImage(profileImage);
    setvisible(false);
  }

  //to show popup
  const showModal = () => {
    setvisible(true);
  };

  //handle ok on model footer.For now footer disabled
  const handleOk = e => {
    // console.log(e);
    setvisible(false);
  };

  //handle cancel on model footer.For now footer disabled
  const handleCancel = e => {
    // console.log(e);
    setvisible(false);
  };

  //to remove previous image from profile
  function updatingremovedprofile() {
    var imageData = "";
    const formData = new FormData();
    const get_id = localStorage.getItem("id");
    formData.append('id', get_id);
    onImageChange(formData);
    handleImageChange(imageData);
  }

  function imagedata(imgformdata) {
    onImageChange(imgformdata);
  }

  //for removing profile picture
  const removeprofilepicture = () => {
    setvisible(false);
    setdata_inside_model(
      <div className="col-md-12 text-center removepopup">
        <p className="textcolor1 sectionTitle">{t("Remove profile photo")}?</p>
        <Button className="bg-color  action-btn mx-2" type="primary" onClick={() => setvisible(false)}>{t("Cancel")}</Button>
        <Button className="bg-color action-btn mx-2" type="primary" danger onClick={updatingremovedprofile}>{t("Remove")}</Button>
      </div>
    )
    showModal();
  }
  //checking user selcet choose avatar option then displaying the avatar page 
  const Avaatarpopup = () =>{
    setAvatarImage(true)
    setvisible(false);
    AvatageImages();
  }

  function updateprofilePage () {
    AvatageImages()
  }
 //passing the img url thorugh props avatar page 
  function passurl(imageurl) {  
    handleImageChange(imageurl);
    // const formData = new FormData();
  }
  //checking avatar hide or show 
  function AvaatarStatus () {
    setAvatarImage(false)
  }
//user change profile image then provide two options choose image and choose Avataar image 
  const modalpopup = () => {
    setdata_inside_model(
      <div>
        <div className="col-md-12 px-0 d-flex justify-content-center">
          <span className="btn  chose-img px-2 py-1 mr-2 closeButton m-0" variant="secondary" onClick={displaypopup} >
            {t('Choose Image')}
          </span>
          <button className="bg-color btn w-auto px-2 py-1  m-0" variant="primary"  onClick={Avaatarpopup}>
            {t('Choose Aavtar')}
          </button>
        </div>
      </div>
    );
    showModal();
  }
  const displaypopup = () => {
    setdata_inside_model(
      <div className='text-center'>
        <div>
          <img
            src={profileImage === "" ? Avatar_dummy : profileImage} alt="profileImage"
            style={{ width: "300px" }}
          ></img>
        </div>
        {/* react avator editor */}
        <div className='col-md-12 col-12 text-center d-flex justify-content-center'>
        {profileImage === "" ? (<> <span onClick={() => setvisible(false)}> <ImageAvatar handleImageChange={handleImageChange} imagedata={imagedata} /> </span> </>) : (<> <span> <label className="btn bg-color mt-4 mb-1 ml-3" onClick={removeprofilepicture} > <span>{t("Remove")}</span> </label> </span>
          <span className='float-left' onClick={() => setvisible(false)}> <ImageAvatar handleImageChange={handleImageChange} imagedata={imagedata} /> </span> </>)}
        </div>
      </div>
    );
    showModal();
  }


  return (
    <div className="profile-image-main-div mt-4 pt-2">
      {AvatarImage ? null : <div>
        <Avatar size={85} icon={<UserOutlined />} src={profileImage} />
        <div className="iconmainstyle">
          <div className="iconstyle" onClick={modalpopup}>
            <CreateOutlinedIcon style={{ color: 'white' }} />
            </div>
            </div>
            </div>}
        <Modal
        centered
        className="profilepic"
        title={t("Profile picture")}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {/* Constructed data to display on model */}
        {data_inside_model}
      </Modal>
      {AvatarImage ? <Avatars value={Attributes} onChange={setAttributes} passurl={passurl} imagedata={imagedata} updateprofilePage = {updateprofilePage} AvaatarStatus = {AvaatarStatus}/> :null}
    </div>
  )
}
