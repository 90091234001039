
import React, { useState, useEffect } from 'react';
import './popupStyle.css';
import { Modal, Button } from "react-bootstrap";
import { t } from '../../Translation/Translation'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RightIcon from "../../Static/images/rightMark.svg";
import WrongIcon from "../../Static/images/wrongMark.svg";
import CloseIcon from "../../Static/images/CloseIcon.svg";
import ErrorIcon from "../../Static/images/error.png";
import { MentionsInput, Mention } from "react-mentions";
import { users as colleagues } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import TimePicker from "react-mobile-datepicker";
import "../BigCalendar/mobilecalendar.css";

const MeetingRoomPopup = (props) => {
  const [date, setDate] = useState(props.date)
  const [startTime, setStartTime] = useState(new Date(props.start_time).toLocaleTimeString('en-GB', { timeStyle: "short" }))
  const [PopupStartTime, setPopupStartTime] = useState(props.start_time)
  const [endTime, setEndTime] = useState(new Date(props.end_time).toLocaleTimeString('en-GB', { timeStyle: "short" }))
  const [PopupEndTime, setPopupEndTime] = useState(props.end_time)
  const [subject, setSubject] = useState(props.subject)
  const [roomId, setRoomId] = useState(props.roomid)
  const [title, setTitle] = useState(props.title)
  const [location, setLocation] = useState(props.location)
  const [users, setUsers] = useState(props.attendees ? props.attendees : [])
  const [usersdata, setUsersData] = useState('')
  const [usersList, setUsersList] = useState(props.usersList ? props.usersList : [])
  const [message, setMessage] = useState('');
  const [subjectmessage, setSubjectMessage] = useState('');
  const [starttimemessage, setStartTimeMessage] = useState('');
  const [endtimemessage, setEndTimeMessage] = useState('');
  const [entryId, setEntryId] = useState(props.entryId ? props.entryId : 0);
  const [refresh, setRefresh] = useState(false);
  const [colleaguesData, setColleaguesData] = useState('');
  const [colleagueData, setcolleagueData] = useState("");
  const [mentionedUsers, setmentionedUsers] = useState() 
  const [starttimepopup, setStartTimePopup] = useState(false)
  const [endtimepopup, setEndTimePopup] = useState(false)

  const current = new Date();
  const current_date = current.toLocaleDateString('en-GB')

  useEffect(() => {
    const get_id = localStorage.getItem("id");
    //fetching the all user list
    APICALL.service(colleagues, "GET").then((result) => {
      if (result) {
        try {
          //filtering the current login user details
          const userMention = result.data
            .filter((e) => e.id !== parseInt(get_id))
            .map((myUser) => ({
              id: myUser.email,
              display: myUser.firstName + " " + myUser.lastName,
              
            }));
          setColleaguesData(userMention);
        } catch (e) {
          console.error(e);
        }
      }
    });
  }, []);


  useEffect(() => {
    setStartTimeMessage('');
    setEndTimeMessage('');
    setMessage('');
    setSubjectMessage('');
    setUsersData('')
    setUsersList(props.usersList)
    setcolleagueData("")

    setSubject(props.subject);
    setRoomId(props.roomid);
    setTitle(props.title);
    setEntryId(props.entryId)
    setLocation(props.location);
    setDate(props.date);
    setPopupStartTime(props.start_time)
    setStartTime(new Date(props.start_time).toLocaleTimeString('en-GB', { timeStyle: "short" }))
    setEndTime(new Date(props.end_time).toLocaleTimeString('en-GB', { timeStyle: "short" }))
    setPopupEndTime(props.end_time)

    setStartTimePopup(false)
    setEndTimePopup(false)

  }, [props]);

  useEffect(() => {
    setUsers(props.attendees)
  }, [props, refresh]);


  useEffect(() => {
    // Timer for success messages
    if (props.type === 0) {
      const timer = setTimeout(() => {
        if (props.type === 0) { props.onHide(); }
      }, 3000);
    }
  }, [props.type]);

  function ValidateEmail(mail) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
      setMessage('')
      return (true)
    } else if (mail === "") {
      setMessage('')
      return false
    }
    setMessage('Enter a valid email address')
    return (false)
  }

  // //Function to add selected members
  // const AddUsers = (event) => {
  //   if (event.keyCode === 13) {
  //     let user = event.target.value.split(' ').pop()
  //     if (users.includes(user)) {
  //       setMessage('This email already added')
  //     } else {
  //       if (ValidateEmail(user) === true) {
  //         users.push(user)
  //         // setUsersData('')
  //       }
  //     }
  //   }else if(event.target.value.split(' ').pop().length === 1){
  //     setMessage('')
  //   }
  // }


  function includeObject(arr,obj) {
    return  arr.some(code => JSON.stringify(code) === JSON.stringify(obj));
  }

  //Function to add selected members
  const AddUsers = (event) => {
    setMessage('')
    console.log('event:', event)
    if (event.keyCode === 13 || event.keyCode === 32 || event.keyCode == 32) {

      if (mentionedUsers !== undefined && mentionedUsers.length > 0) {
        Object.keys(mentionedUsers).map((value, i) => {
          let email = mentionedUsers[value]['id'];
          console.log(!includeObject(users, mentionedUsers[value]))
          // console.log("containsObject(obj, list)", containsObject(mentionedUsers[value], users));
          if ((!includeObject(users, mentionedUsers[value]) ) && !(usersList.includes(email))){
            users.push(mentionedUsers[value])
            usersList.push(email)
          }
        })
        setcolleagueData("")
      }
      else {
        let user = event.target.value
        if (users.includes(user)) {
          setMessage('This email already added')
        }
        else if (usersList.includes(user)) {
          setMessage('This email is already added')
        }
        else {
          if (ValidateEmail(user) === true) {
            users.push(user)
            usersList.push(user)
            setcolleagueData('')
          }
          else if(event.target.value.split(' ').pop().length === 1){
            setMessage('')
            setcolleagueData('')
          }
          else {
            // setcolleagueData('')
          }
        }
      }
      setmentionedUsers()
    } 
  }

  function removeArr(arr, val) {
    if (props.buttonStatus !== 0) {
      arr = arr.splice(val, 1)
      let popValue = arr[0]['id'] !== undefined  ? arr[0]['id'] : arr[0];
      setUsersList(usersList.filter(item => item !== popValue));
   
      for (var i = 0; i > arr.length; i++) {
        users.push(arr[i])
      }
      setRefresh(refresh ? false : true)
    }
  }

  const SetTime = (value, num) => {
    let regex = new RegExp("([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}")
    if (num === 2) {
      setEndTime(value)
      if (/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value)) {
        let time = new Date(current.getFullYear(), current.getMonth(), current.getDate(), value.split(':')[0], value.split(':')[1])
        CheckValidations(time, num)
      } else {
        setEndTimeMessage('Enter valid end time')
      }
    } else {
      setStartTime(value)
      if (/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value)) {
        let time = new Date(current.getFullYear(), current.getMonth(), current.getDate(), value.split(':')[0], value.split(':')[1])
        CheckValidations(time, num)
      } else {
        setStartTimeMessage('Enter valid start time')
      }
    }
  }

  const CheckValidations = (value, num) => {
    var givenStartTime = startTime.split(':')
    var start = givenStartTime[0] * 60 + givenStartTime[1] * 1
    var givenEndTime = endTime.split(':')
    var end = givenEndTime[0] * 60 + givenEndTime[1] * 1
    var selected_date = date.toLocaleDateString('en-GB')
    var current_time = current.getHours() * 60 + current.getMinutes()
    if (num !== 0 && num !== 3) {
      var value_time = value.toLocaleTimeString('en-GB', { timeStyle: "short" })
      var time = value_time.split(':')
      var val = time[0] * 60 + time[1] * 1
    }
    if (num === 0) {
      setDate(value)
      if (current_date === value.toLocaleDateString('en-GB') && current_time > start) {
        setStartTimeMessage('Enter valid start time')
      } else { setStartTimeMessage('') }
      if (current_date === value.toLocaleDateString('en-GB') && current_time > end) {
        setEndTimeMessage('Enter valid end time')
      } else { setEndTimeMessage('') }

    }
    else if (num === 1) {
      setPopupStartTime(value)
      setStartTime(value.toLocaleTimeString('en-GB', { timeStyle: "short" }))
      if (current_date === selected_date && current_time > val) {
        setStartTimeMessage('Enter valid start time')
      } else if (value === '') {
        setStartTimeMessage('Enter valid start time')
      } else {
        setStartTime(value.toLocaleTimeString('en-GB', { timeStyle: "short" }))
        setStartTimeMessage('')
        if (val > end) {
          setEndTimeMessage('Enter valid end time')
        } else { setEndTimeMessage('') }
      }
    } else if (num === 2) {
      setPopupEndTime(value)
      setEndTime(value.toLocaleTimeString('en-GB', { timeStyle: "short" }))
      if (current_date === selected_date && current_time >= val) {
        setEndTimeMessage('Enter valid end time')
      } else if (value === '') {
        setEndTimeMessage('Enter valid end time')
      } else if (start >= val) {
        setEndTimeMessage('Enter valid end time')
      } else {
        setEndTime(value.toLocaleTimeString('en-GB', { timeStyle: "short" }))
        setEndTimeMessage('')
      }
    } else {
      if (value.trim().length === 0) {
        setSubject(value)
        setSubjectMessage('Please enter subject')
      } else {
        setSubject(value)
        setSubjectMessage('')
      }
    }
  }

  const ConstructApiData = (type) => {
    let get_id = localStorage.getItem('id')
    var start = new Date(date.getFullYear(), date.getMonth(), date.getDate(), startTime.split(':')[0], startTime.split(':')[1]).toISOString();
    var end = new Date(date.getFullYear(), date.getMonth(), date.getDate(), endTime.split(':')[0], endTime.split(':')[1]).toISOString();

    const dateAsString = current.toString();
    const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];

    var startdatetime = {
      'dateTime': start.replace(start.split('.').pop(), '00Z'),
      'timeZone': timezone
    }
    var enddatetime = {
      'dateTime': end.replace(end.split('.').pop(), '00Z'),
      'timeZone': timezone
    }
    console.log(usersList)
    let action = 'create'
    if (type === 2) { action = 'update' } else if (type === 3) { action = "delete" }

    if (message.length !== 0 || endtimemessage.length !== 0 || starttimemessage.length !== 0 || subjectmessage.length !== 0) {
      return false
    } else {
      let data = {
        "action": action,
        "entryId": entryId,
        "subject": subject,
        "userid": get_id,
        "room": roomId,
        "location": localStorage.getItem('MeetingroomLocation'),
        "start": startdatetime,
        "end": enddatetime,
        "attendees": usersList,
      }
      return data
    }

  }
  const dateConfig = {
    'hour': {
      format: 'hh',
      caption: 'Hour',
      step: 1,
    },
    'minute': {
      format: 'mm',
      caption: 'Min',
      step: 1,
    },
  };

  const OpenTimePopup = (val) => {
    if (val === 1) {
      setStartTimePopup(true)
      setEndTimePopup(false)
    } else {
      setStartTimePopup(false)
      setEndTimePopup(true)
    }
  }

  const hideTimePopup = () => {
    setStartTimePopup(false)
    setEndTimePopup(false)
  }

  const Createmeeting = (type) => {
    if (props.buttonStatus !== 0) {
      let data = ConstructApiData(type)
      if (data !== false) {
        // console.log('data', data);
        props.onHide();
        let success = props.onSuccess(data, type)
      } else if (data === false && type === 3) {
        let data = {
          'action': 'delete',
          'entryId': entryId
        }
        props.onHide();
        let success = props.onSuccess(data, type)
      }
    }
  }

  const CancelOrDelete = () => {
    if (props.type === 1) {
      props.onHide()
    } else {
      Createmeeting(3)
    }
  }

  function handleHide() {
    // setUsersData('');
    props.onHide();
  }



  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    //update tagging userId deatiails
    // setmentionid(mentions);
    let text = event.target.value;
    // console.log("newValue", newValue, "newPlainTextValue", newPlainTextValue, "mentions", mentions);
    ////display in the text box for user
    // setcolleagueData(text === "@" ? text : "");
    setcolleagueData(text.trim());
    setmentionedUsers(mentions);
    // if (text !== undefined && text.length > 1)
    // AddUsers(newPlainTextValue);
    // if (mentions.length > 0) AddUsers(mentions[0].display)
    //to send in the API for backend
    // setcommentText(text);
  };

  return (
    <Modal
      show={props.show}
      // onHide={props.onHide}
      onHide={handleHide}
      // size={props.size ? props.size : "sm"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={props.type !== 0 ? localStorage.getItem("DeviceMode") === "Desktop" ? "popup-place" : 'popup-place-mob'  : localStorage.getItem("DeviceMode") === "Desktop" ?'message-popup-place' : 'message-popup-mob'}
    >

      <Modal.Header className="d-inline align-items-center border-0" onClick={() => hideTimePopup()}>
        {props.type === 0 && <img alt="dummy-icon" src={props.deletestatus === 0 ? RightIcon : props.errorstatus === 0 ? WrongIcon : ErrorIcon} className="popup-image"></img>}
        <Modal.Title id="" className="textcolor1 text-center">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <div className="click-box" onClick={() => hideTimePopup()}></div>
        {props.type !== 0 ? <form>
          <div className="col-12 row m-0 my-1 form-group" onClick={() => hideTimePopup()}>
            <label className="col-6 col-md-4 row m-0 pl-0 float-left">Date:<p className="text-secondary">*</p></label>
            <div className="meeting-date-picker col-6 col-md-8 px-0" >
              <DatePicker
                className="col-12 border-bottom border-top-0 border-left-0 border-right-0 float-right"
                // selected={new Date(date)}
                selected={date}
                minDate={new Date()}
                onChange={date_val => CheckValidations(date_val, 0)}
                dateFormat="dd-MM-yyyy"
                showMonthDropdown
                showYearDropdown
                placeholderText={t("Select meeting date")}
                dropdownMode="select"
              />
            </div>
          </div>
          {starttimemessage.length > 0 &&
            <small className="col-6 col-md-8 float-right text-danger text-left">{starttimemessage}</small>
          }
          <div className="col-12 row m-0 my-1 form-group popup-input">
            <label className="col-6 col-md-4 pl-0 row m-0 float-left" onClick={() => hideTimePopup()}>Start time:<p className="text-secondary">*</p></label>
            <input value={startTime} onClick={() => OpenTimePopup(1)} onChange={(e) => SetTime(e.target.value, 1)} pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" className="col-6 col-md-8 float-right border-top-0 border-left-0 border-right-0 border-bottom" />

            {starttimepopup && <div className="col-6 p-0 timepicker shadow">
              <TimePicker
                showCaption={false}
                dateConfig={dateConfig}
                isOpen={true}
                onChange={(e) => CheckValidations(e, 1)}
                value={new Date(PopupStartTime)}
                showHeader={false}
                isPopup={false}
                theme={"android"}
                showFooter={false}
              /></div>}
            {/* <input type="time" value={startTime} onChange={(e) => CheckValidations(e.target.value, 1)} className="col-6 col-md-8 float-right border-top-0 border-left-0 border-right-0 border-bottom" /> */}
          </div>
          {endtimemessage.length > 0 &&
            <small className="col-6 col-md-8 float-right text-danger text-left">{endtimemessage}</small>
          }
          <div className="col-12 row m-0 my-1 form-group popup-input">
            <label className="col-6 col-md-4 row m-0 pl-0 float-left" onClick={() => hideTimePopup()}>End time:<p className="text-secondary">*</p></label>
            <input type="text" value={endTime} onClick={() => OpenTimePopup(2)} onChange={(e) => SetTime(e.target.value, 2)} pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" className="col-6 col-md-8 float-right border-top-0 border-left-0 border-right-0 border-bottom" />
            {endtimepopup && <div className="col-6 p-0 timepicker shadow">
              <TimePicker
                showCaption={false}
                dateConfig={dateConfig}
                isOpen={true}
                onChange={(e) => CheckValidations(e, 2)}
                value={new Date(PopupEndTime)}
                showHeader={false}
                isPopup={false}
                theme={"android"}
                showFooter={false}
              /></div>}
            {/* <input type="time" value={endTime} onChange={(e) => CheckValidations(e.target.value, 2)} className="col-6 col-md-8 float-right border-top-0 border-left-0 border-right-0 border-bottom" /> */}
          </div>
          {subjectmessage.length > 0 &&
            <small className="col-6 col-md-8 float-right text-danger text-left">{subjectmessage}</small>
          }
          <div className="col-12 row m-0 my-1 form-group popup-input" onClick={() => hideTimePopup()}>
            <label className="col-6 col-md-4 row m-0 pl-0 float-left">Subject:<p className="text-secondary">*</p></label>
            <input type="text" value={subject} onChange={(e) => CheckValidations(e.target.value, 3)} className="col-6 col-md-8 float-right border-top-0 border-left-0 border-right-0 border-bottom" />
          </div>
          {message.length > 0 &&
            <small className="col-6 col-md-8 float-right text-danger text-left">{message}</small>
          }
          <div className="col-12 row m-0 my-1 form-group popup-input meeting-popup" onClick={() => hideTimePopup()}>
            <label className="col-6 col-md-4 pl-0 float-left">Invite colleagues:</label>
            {/* <input type="text" value={usersdata} onChange={(e) => setUsersData(e.target.value)} onKeyDown={(e) => AddUsers(e)} autoComplete="on" className="col-6 col-md-8 float-right border-top-0 border-left-0 border-right-0 border-bottom" /> */}
            <MentionsInput
            value={colleagueData}
            onChange={handleChange}
            className="mentions commentBox col-6 col-md-8 border-bottom float-right"
            placeholder={t("Type an email or @")}
            allowSuggestionsAboveCursor={true}
            onKeyDown={(e) => AddUsers(e)}>
            <Mention
              type="user"
              trigger="@"
              data={colleaguesData}
              // markup="@@@____id__^^^____display__@@@^^^"
              className="mentions__mention"
              displayTransform={(id, name) => `${name}`}
              //style={ background-color: #cee4e5}
              style={{
                backgroundColor: "#d20c14",
              }}
            />
          </MentionsInput>
            
            {users !== undefined && users.length !== 0 &&
              <div className="col-12 p-0 mt-2 border mx-0 user-box">
                {
                  Object.keys(users).map((value, i) => {
                    return (
                      // <p className="row border px-1 m-1 mb-2 user-selected" key={value}>{users[value]} <img src={CloseIcon} onClick={() => removeArr(users, value)} alt="close_icon" className="closeIcon-width ml-2"></img></p>
                      <p className="row border px-1 m-1 mb-2 user-selected" key={value}>{users[value].display !== undefined ? users[value].display : users[value]} <img src={CloseIcon} onClick={() => removeArr(users, value)} alt="close_icon" className="closeIcon-width ml-2"></img></p>
                    )
                  })
                }
              </div>
            }
          </div>
        </form> :

          <div className="text-center mb-4">
            {props.deletestatus === 0 && <p className="mb-1">{location}</p>}
            {props.deletestatus === 0 && <p className="mb-1">Meeting room: {subject}</p>}
            {props.deletestatus === 0 && <p className="mb-1">{startTime}-{endTime}</p>}
          </div>
        }
      </Modal.Body>

      {(props.type !== 0) &&
        <Modal.Footer className="border-0 justify-content-center" onClick={() => hideTimePopup()}>
          <Button className="bg-secondary border border-secondary rounded" onClick={() => CancelOrDelete()}>
            {props.button1}
          </Button>
          <Button className="bg-color popupborder rounded" onClick={() => Createmeeting(props.type)}>
            {props.button2}
          </Button>
        </Modal.Footer>}
    </Modal>
  );
}

export default MeetingRoomPopup
