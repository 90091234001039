import React, { useState, useEffect } from 'react';
import { EventUrl } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import Image from 'react-bootstrap/Image';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import CommentAdd from '../Common/comments/CommentAdd';
import Slider from "../../Utilities/Slider/Slider";
import LocationOnMap from "../../Pages/Locations/LocationOnMap";
import '../../Static/css/common.css';
import { t } from '../../Translation/Translation'
import FullScreenImage from "../../Utilities/FullScreenImage/FullScreenImage";
import Popup from "../../Utilities/Popup/modelPopup";
import CommentDetailsPages from '../Common/comments/CommentDetails';
import BackButtonComponent from '../../Utilities/BackButtonComponent';
import { useParams } from "react-router";
import previewFn from '../../Common/Preview';
// import { ICSfile } from "../../Services/ApiEndPoints";
import calendarGenerators from "../../Utilities/ICSCalenders/calender";
// import DateTime from 'react-datetime';
import BackButton from "../../Utilities/BackButton";

function EventDetails({ updatesEntityId, CommentsStatus, backButton }) {
  const [eventslist, seteventlist] = useState()
  const history = useHistory();
  const [sliderData, setsliderData] = useState("");
  const [BannerTime, setBannerTime] = useState();
  const [fullscreenState, setfullscreenState] = useState(false);
  const [imageSrc, setimageSrc] = useState("");
  const [message, setMessage] = useState("")
  const [commentDetails, setcommentDetails] = useState(false);
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  const [eventHref,seteventHref] =  useState("");
  const [previewBack, setpreviewBack] = useState(false);
  // const [venue,setVenue] =  useState("");
  // const [eventStart,seteventStart] =  useState("");
  // const [eventStart,seteventStart] =  useState("");

  let params = useParams();

  useEffect(() => {
    let currentpath = window.location.href;
    if (currentpath.indexOf("preview") > 0){
      setpreviewBack(true);
    }
  }, [])

  useEffect(() => {
    if (urlState === false && updatesEntityId !== undefined) {
      setbackbuttonUrl('/events/' + updatesEntityId.id);
      seturlState(true);
    }
  }, [updatesEntityId])

  useEffect(() => {
    setcommentDetails(CommentsStatus);
    const get_id = localStorage.getItem("id");
    let param = previewFn(params);
    let data = {
      'user_id': get_id,
      'entry_id': updatesEntityId.id,
      'param': param
    }
    APICALL.service(EventUrl, "POST", data)
      .then(result => {
        if (result !== undefined || result !== '' || result !== null) {
          try {
            setBannerTime(result[updatesEntityId.id].banners_time ? result[updatesEntityId.id].banners_time : null)
            let deviceMode = localStorage.getItem("DeviceMode");

            if(deviceMode === "Desktop"){
              _constructSliderData(result[updatesEntityId.id].banner_images);
            }else{
              _constructSliderData(result[updatesEntityId.id].mobile_banner_images);
            }
            seteventlist(result)

            let event = {
              'title': result[data.entry_id].title,
              'start': result[data.entry_id].start_date,
              'end': result[data.entry_id].end_date,
              'venue': result[data.entry_id].venue.address,
              'url': result[data.entry_id].call_to_action_url,
            }
            let v = calendarGenerators.ical(event)
            seteventHref(v)

          } catch (e) {
            console.error(e);
          }

        }
      })
      .catch(error => {
        console.error("Eventlist error : ", error);
      })
  }, [updatesEntityId, eventHref]);

  //Function to construct data for slideshow
  const _constructSliderData = (data) => {
    const sliders = [];
    data.length > 0 && data.forEach((val, i) => {
      sliders[i] = { 'original': val.image, 'thumbnail': val.image };
    })
    setsliderData(sliders);

  }
  //
  function getLocation() {
    navigator.geolocation.getCurrentPosition(
      getcoordinates,
      errorGetcoordinates
    );
  }

  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  //map coordinates and redirection to map
  function errorGetcoordinates() {
    if (
      navigator.platform.indexOf("iPhone") !== -1 ||
      navigator.platform.indexOf("iPod") !== -1 ||
      navigator.platform.indexOf("iPad") !== -1
    ) {
      setMessage(
        t("Provide access in") + ":" + t("Settings") + ">" + t("Privacy") + ">" + t("Location Services") + ">" + t("Safari")
      );
    } else {
      setMessage(
        t("Error getting the user location, please check device permission") + "."
      );
    }
  }

  //map coordinates and redirection to map
  function getcoordinates(position) {
    var url;
    Object.keys(eventslist).map((key) => {
      if ((navigator.platform.indexOf("iPhone") !== -1)
        || (navigator.platform.indexOf("iPod") !== -1)
        || (navigator.platform.indexOf("iPad") !== -1)) {
        url = 'https://www.google.com/maps/dir/' + position.coords.latitude + ',' + position.coords.longitude + '/' + eventslist[key].venue.map_latitude + ',' + eventslist[key].venue.map_longitude;
        window.location.assign(url, "_blank");
      } else {
        url = 'https://www.google.com/maps/dir/' + position.coords.latitude + ',' + position.coords.longitude + '/' + eventslist[key].venue.map_latitude + ',' + eventslist[key].venue.map_longitude;
        window.open(url, "_blank");
      }
    });
  }

  //To return HTML from string containing HTML tags
  function createMarkup(data) {
    if(data){
      data = data.replaceAll("<p></p>", "<br />");
    }
    return { __html: data };
  }
  //converting  UTC to local time ?
  function convertUTCDateToLocalDate(date) {
    let d = new Date(date.toString());
    return d.toString();
  }
  //To enable image in full screen
  function EnableFullScreenImage(ImageData) {
    setimageSrc(ImageData);
    setfullscreenState(true);
  }
  //To Disable image in full screen
  function DisableFullScreenImage() {
    setimageSrc("");
    setfullscreenState(false);
  }
  //To close alert popup
  const closeAlertPopup = () => {
    setMessage("");
  };
  //updating commentDeatils pages status
  function CommentStatus(e) {
    setcommentDetails(e);
  }
  const backButtons = () => {
    setcommentDetails(false);
  }

  // back button funtionality
  function historyBack() {
    let response = BackButton("/events");
    if (response === false) {
      backButton(false);
      history.push('/events');
    }
    else {
      backButton(false);
      history.push(response);
    }

  }
  return fullscreenState ? (
    <FullScreenImage
      imageSrc={imageSrc}
      DisableFullScreenImage={DisableFullScreenImage}
    />
  ) : (
    <div className="container customContainer">
      { commentDetails ? <CommentDetailsPages updatesEntityId={updatesEntityId.id} backButtons={backButtons} /> :
        eventslist !== undefined &&
        eventslist.status !== "404" &&
        Object.keys(eventslist).map((key) => (
          <div className="" key={key}>
            {
              localStorage.getItem('DeviceMode') !== "Desktop" ? 
            <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4">
              {previewBack ? null : <p className="browserback" onClick={historyBack}>back</p>}
              {backbuttonUrl && <BackButtonComponent backButtonLink = {backbuttonUrl}/>}
              <h1 className="pagetitle textcolor1 text-center">
                {t("Events")}
              </h1> 
            </div>: null
            }
            {sliderData.length > 0 && (
              <Slider
                history={history}
                bannerTime={BannerTime ? BannerTime : "5"}
                sliderData={sliderData}
                EnableFullScreenImage={EnableFullScreenImage}
              />
            )}
            <div className="col-12 px-2 mt-4">
              <div className="row">
                <div className="col-10">
                  {" "}
                  <h4 className="card-title">
                    {eventslist[key].title ? eventslist[key].title : null}
                  </h4>
                </div>

                <div className="col-2 text-right">
                  {" "}
                  <p className="eventIcon eventdetailicon imgSprite"></p>
                </div>


                <div className="col-md-12 text-right mb-3">
                  {eventslist[key].call_to_action_name !== null &&
                    eventslist[key].call_to_action_name !== "" &&
                    eventslist[key].call_to_action_name !== undefined &&
                    eventslist[key].call_to_action_url !== null &&
                    eventslist[key].call_to_action_url !== "" &&
                    eventslist[key].call_to_action_url !== undefined ? (
                    <span className="textcolor1 ">
                      <a
                        href={eventslist[key].call_to_action_url}
                        className="textcolor1"
                      >
                        {eventslist[key].call_to_action_name}
                      </a>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>


            <div className="mobilediv">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-6 pl-0 startdate">
                    <p className="px-2 textcolor1 pt-3 mb-0">
                      {" "}
                      {t("Start date")}
                    </p>
                    <p className="px-2 py-2 mb-0">
                      {eventslist[key].start_date
                        ? moment(
                          convertUTCDateToLocalDate(eventslist[key].start_date)
                        ).format("DD/MM/YYYY")
                        : null}
                    </p>
                  </div>
                  <div className="col-6 pr-0 enddate">
                    <p className="px-2 textcolor1 pt-3 mb-0"> {t("End date")}</p>
                    <p className="px-2 py-2 mb-0">
                      {eventslist[key].end_date
                        ? moment(
                          convertUTCDateToLocalDate(eventslist[key].end_date)
                        ).format("DD/MM/YYYY")
                        : null}
                    </p>
                  </div>
                  <div className="col-6 pl-0 startdate">
                    <p className="px-2 textcolor1 pt-3 mb-0">
                      {" "}
                      {t("Start time")}
                    </p>
                    <p className="px-2 py-2 mb-0">
                      {eventslist[key].start_date
                        ? moment(
                          convertUTCDateToLocalDate(eventslist[key].start_date)
                        ).format("HH:mm")
                        : null}
                    </p>
                  </div>
                  <div className="col-6 pr-0 enddate">
                    <p className="px-2 textcolor1 pt-3 mb-0"> {t("End time")}</p>
                    <p className="px-2 py-2 mb-0">
                      {eventslist[key].end_date
                        ? moment(
                          convertUTCDateToLocalDate(eventslist[key].end_date)
                        ).format("HH:mm")
                        : null}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-12 mt-3">
                <div className="row">
                  <div className="col-8 pl-0 detailInnertitles">
                      <p className="locationRed imgSprite eventlocation mb-5"></p>
                    <p className="mb-0 ml-4 pl-2">
                      {eventslist[key].venue.address
                        ? eventslist[key].venue.address
                        : null}
                    </p>
                  </div>
                  {/* <div className="col-8 pl-0 detailInnertitles">
                    <div className="row">
                      <div className="col-2">
                        <p className="locationRed imgSprite eventlocation mb-5"></p>
                      </div>
                      <div className="col-10">
                        <p>
                          {eventslist[key].venue.address
                            ? eventslist[key].venue.address
                            : null}
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-4 px-0 pr-2 text-right">
                    <div className="col-12 px-0 add-to-agenda">
                      <h4 className="card-title">
                        {/* <a
                          href={eventHref}
                          className="btn bg-color"
                        > */}
                          <a href={eventHref} className="btn bg-color px-2 px-1">
                          {t("Add to agenda")}
                        </a>
                      </h4>
                    </div>
                    <Popup
                      show={message !== "" ? true : false}
                      onHide={closeAlertPopup}
                      size={"lg"}
                      title={"Alert"}
                      body={message}
                    />
                    <span
                      className={
                        eventslist[key].venue.map_latitude != null
                          ? "textcolor1 mb-0 pt-3"
                          : "disableLink mb-0"
                      }
                      onClick={() => getLocation()}
                    >
                      <span className='go-btn btn bg-color'>{t("Go!")}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 my-3 px-0 map-border-bottom">
                {eventslist[key].venue.map_latitude !== null ? (
                  <LocationOnMap
                    lats={eventslist[key].venue.map_latitude}
                    long={eventslist[key].venue.map_longitude}
                    mapaddress={eventslist[key].venue.map_details}
                    type="events"
                  />
                ) : null}
              </div>
            </div>


            <div className="desktopdiv">
              <div className="row">
                <div className="col-lg-6">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-6 pl-0 startdate py-2">
                        <p className="px-2 textcolor1 pt-3 mb-0">
                          {" "}
                          {t("Start date")}
                        </p>
                        <p className="px-2 py-2 mb-0">
                          {eventslist[key].start_date
                            ? moment(
                              convertUTCDateToLocalDate(eventslist[key].start_date)
                            ).format("DD/MM/YYYY")
                            : null}
                        </p>
                      </div>
                      <div className="col-6 pr-0 enddate py-2">
                        <p className="px-2 textcolor1 pt-3 mb-0"> {t("End date")}</p>
                        <p className="px-2 py-2 mb-0">
                          {eventslist[key].end_date
                            ? moment(
                              convertUTCDateToLocalDate(eventslist[key].end_date)
                            ).format("DD/MM/YYYY")
                            : null}
                        </p>
                      </div>
                      <div className="col-6 pl-0 startdate py-2">
                        <p className="px-2 textcolor1 pt-3 mb-0">
                          {" "}
                          {t("Start time")}
                        </p>
                        <p className="px-2 py-2 mb-0">
                          {eventslist[key].start_date
                            ? moment(
                              convertUTCDateToLocalDate(eventslist[key].start_date)
                            ).format("HH:mm")
                            : null}
                        </p>
                      </div>
                      <div className="col-6 pr-0 enddate py-2">
                        <p className="px-2 textcolor1 pt-3 mb-0"> {t("End time")}</p>
                        <p className="px-2 py-2 mb-0">
                          {eventslist[key].end_date
                            ? moment(
                              convertUTCDateToLocalDate(eventslist[key].end_date)
                            ).format("HH:mm")
                            : null}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-lg-6">
                  <div className="col-12 mb-3 px-0">
                    {eventslist[key].venue.map_latitude !== null ? (
                      <LocationOnMap
                        lats={eventslist[key].venue.map_latitude}
                        long={eventslist[key].venue.map_longitude}
                        mapaddress={eventslist[key].venue.map_details}
                        type="events"
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-12 mt-3">
                <div className="row">
                  <div className="col-9 pl-0 detailInnertitles">
                    <p className="locationRed imgSprite eventlocation mb-5"></p>
                    {eventslist[key].venue.address
                      ? <p>{eventslist[key].venue.address.split(',')[0]} <br />{eventslist[key].venue.map_details.sublocality_level_1} <br /> {eventslist[key].venue.map_details.city} <br /> {eventslist[key].venue.map_details.state} <br /> <span className="ml-4 pl-1">{eventslist[key].venue.map_details.country}</span> <br /></p>
                      : null}

                  </div>
                  <div className="col-3 px-0 pr-2 text-right go-align-event">
                    <div className="col-12 add-to-agenda">
                      <h4 className="card-title">
                        <a
                          href={eventHref}
                          className="btn bg-color"
                        >
                          {t("Add to agenda")}
                        </a>
                      </h4>
                    </div>
                    <Popup
                      show={message !== "" ? true : false}
                      onHide={closeAlertPopup}
                      size={"lg"}
                      title={"Alert"}
                      body={message}
                    />
                    <span
                      className={
                        eventslist[key].venue.map_latitude != null
                          ? "textcolor1 mb-0 pt-3 "
                          : "disableLink mb-0"
                      }
                      onClick={() => getLocation()}
                    >
                      <span className="btn bg-color go-btn">{t("Go!")}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-12 p-0 my-3 Richtexterditor"
              dangerouslySetInnerHTML={createMarkup(eventslist[key].content)}
            />
            {Array.isArray(eventslist[key].images)
              ? eventslist[key].images.map((images, index) => (
                <div
                  className="col-12 p-0 text-center"
                  key={index}
                  onClick={() => EnableFullScreenImage(images)}
                >
                  <Image className="mb-3" src={images} rounded fluid />
                </div>
              ))
              : null}
            {eventslist[key].comment_status !== "disabled" ? (
              <CommentAdd entryId={updatesEntityId.id} CommentStatus={CommentStatus}></CommentAdd>
            ) : null}
          </div>
        ))}
      {eventslist != null && eventslist.status === "404" && (
        <p> {t("No Description")}</p>
      )}
    </div>
  );

}
export default EventDetails