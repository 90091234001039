import React, { useState, useEffect } from "react";
import { Locations } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import "./locationstyles.css";
import { useHistory } from "react-router-dom";
import Slider from "../../Utilities/Slider/Slider";
import "../../Static/css/common.css";
import MiniMap from "./LocationOnMap";
import btn1 from "../../Static/images/Ltip.png";
import btn2 from "../../Static/images/Folder.png";
import meeting from "../../Static/images/meeting.png";
import { t } from "../../Translation/Translation";
import FullScreenImage from "../../Utilities/FullScreenImage/FullScreenImage";
import Popup from "../../Utilities/Popup/modelPopup";
import BackButtonComponent from "../../Utilities/BackButtonComponent";
import BackButton from "../../Utilities/BackButton";
import { useParams } from "react-router";
import previewFn from "../../Common/Preview";

function LocationDetails({ updatesEntityId, backButton }) {
  const [locationdata, setlocationdata] = useState({});
  const history = useHistory();
  const [sliderData, setsliderData] = useState("");
  const [fullscreenState, setfullscreenState] = useState(false);
  const [imageSrc, setimageSrc] = useState("");
  const [BannerTime, setBannerTime] = useState();
  const [message, setMessage] = useState("");
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  const [previewBack, setpreviewBack] = useState(false);
  let params = useParams();

  useEffect(() => {
    let currentpath = window.location.href;
    if (currentpath.indexOf("preview") > 0) {
      setpreviewBack(true);
    }
  }, []);

  useEffect(() => {
    if (urlState === false && updatesEntityId !== undefined) {
      setbackbuttonUrl("/ourlocations/" + updatesEntityId);
      seturlState(true);
    }
  }, [updatesEntityId]);

  useEffect(() => {
    const get_id = localStorage.getItem("id");
    let param = previewFn(params);
    let data = {
      user_id: get_id,
      type: "view",
      entry_id: updatesEntityId,
      param: param,
    };
    APICALL.service(Locations, "POST", data).then((result) => {
      if (result !== undefined || result !== "" || result !== null) {
        try {
          setBannerTime(result[updatesEntityId].banner_time);
          let deviceMode = localStorage.getItem("DeviceMode");
          
          if(deviceMode === "Desktop"){
            _constructSliderData(result[updatesEntityId].location_images);
          }else{
            _constructSliderData(result[updatesEntityId].mobile_banner_images);
          }
          setlocationdata(result);
        } catch (e) {
          console.error(e);
        }
      }
    });
  }, [updatesEntityId]);

  // back button funtionality
  function historyBack() {
    let response = BackButton("/ourlocations");
    if (response === false) {
      backButton(false);
      history.push("/ourlocations");
    } else {
      history.push(response);
    }
  }

  //Function to construct data for slideshow
  const _constructSliderData = (data) => {
    const sliders = [];
    data.length > 0 &&
      data.forEach((val, i) => {
        sliders[i] = { original: val.image, thumbnail: val.image };
      });
    if (fullscreenState === true) {
      setfullscreenState(false);
    }
    setsliderData(sliders);
  };

  //To return HTML from string containing HTML tags
  function createMarkup(data) {
    if(data){
      data = data.replaceAll("<p></p>", "<br />");
    }
    return { __html: data };
  }

  function getLocation() {
    navigator.geolocation.getCurrentPosition(
      getcoordinates,
      errorGetcoordinates
    );
  }

  function errorGetcoordinates() {
    if (
      navigator.platform.indexOf("iPhone") !== -1 ||
      navigator.platform.indexOf("iPod") !== -1 ||
      navigator.platform.indexOf("iPad") !== -1
    ) {
      setMessage(
        t("Provide access in") +
          ":" +
          t("Settings") +
          ">" +
          t("Privacy") +
          ">" +
          t("Location Services") +
          ">" +
          t("Safari")
      );
    } else {
      setMessage(
        t("Error getting the user location, please check device permission") +
          "."
      );
    }
  }

  //To close alert popup
  const closeAlertPopup = () => {
    setMessage("");
  };

  //map coordinates and redirection to map
  function getcoordinates(position) {
    var url;
    Object.keys(locationdata).map((key) => {
      if (
        navigator.platform.indexOf("iPhone") !== -1 ||
        navigator.platform.indexOf("iPod") !== -1 ||
        navigator.platform.indexOf("iPad") !== -1
      ) {
        url =
          "https://www.google.com/maps/dir/" +
          position.coords.latitude +
          "," +
          position.coords.longitude +
          "/" +
          locationdata[key].map_latitude +
          "," +
          locationdata[key].map_longitude;
        window.location.assign(url, "_blank");
      } else {
        url =
          "https://www.google.com/maps/dir/" +
          position.coords.latitude +
          "," +
          position.coords.longitude +
          "/" +
          locationdata[key].map_latitude +
          "," +
          locationdata[key].map_longitude;
        window.open(url);
      }
    });
  }

  //when user click on sitemanager name it redirects to whoiswho details page
  function manager_details(fetch_id) {
    var url = "/whoiswho/" + fetch_id;
    if (fetch_id !== undefined) {
      history.push({
        pathname: url,
      });
    }
  }

  //To enable image in full screen
  function EnableFullScreenImage(imgdata) {
    setimageSrc(imgdata);
    setfullscreenState(true);
  }

  //To disable image in full screen
  function DisableFullScreenImage() {
    setimageSrc("");
    setfullscreenState(false);
  }

  const goBackButton = () => {
    if (history.length < 2) {
      history.replace("/ourlocations");
    } else {
      history.goBack();
    }
  };

  return fullscreenState ? (
    <FullScreenImage
      imageSrc={imageSrc}
      DisableFullScreenImage={DisableFullScreenImage}
    />
  ) : (
    <div className="container customContainer">
      <div className="mb-5">
        <div className="justify-content-md-center rounded mb-5">
          <div className="col-md-12  mt-4 mob-space-location">
            <div className="row ">
              {localStorage.getItem("DeviceMode") !== "Desktop" ? (
                <>
                  <div className="col-md-12 topsection-wrap  mt-0 ">
                    {" "}
                    {previewBack ? null : (
                      <p className="browserback top_wrap" onClick={historyBack}>
                        back
                      </p>
                    )}
                    {backbuttonUrl && (
                      <BackButtonComponent backButtonLink={backbuttonUrl} />
                    )}
                    <h1 className="pagetitle textcolor1 text-center">
                      {t("Location")}
                    </h1>
                  </div>
                </>
              ) : null}
            </div>
            {locationdata !== null &&
              Object.keys(locationdata).map((key) => (
                <div className="card-group mt-3" key={locationdata[key].id}>
                  {sliderData.length > 0 && (
                    <Slider
                      history={history}
                      bannerTime={BannerTime ? BannerTime : "5"}
                      sliderData={sliderData}
                      EnableFullScreenImage={EnableFullScreenImage}
                    />
                  )}
                  <div className="card">
                    <div className="card-body mb-5 px-0">
                      <div className=" col-md-12 col-12 px-0">
                        <div className="col-md-12 col-12 pl-1 pr-0">
                          <h5 className="card-title">
                            {locationdata[key].title}
                            <div className="float-right">
                              <p className="locationIcon imgSprite mb-0"></p>
                            </div>
                          </h5>
                        </div>
                        {/* <div className="col-md-12 col-12 px-0 text-right"><p className="textcolor1 mb-0 pt-3" onClick={getLocation}>Get there</p></div> */}
                        <div className="col-md-12 col-12 px-2 text-right pt-3 clearboth">
                          <Popup
                            show={message !== "" ? true : false}
                            onHide={closeAlertPopup}
                            size={"lg"}
                            title={"Alert"}
                            body={message}
                          />

                          <p
                            className={
                              locationdata[key].map_latitude != null
                                ? "textcolor1 mb-0 pt-3 customCursor float-right"
                                : "disableLink mb-0"
                            }
                            onClick={getLocation}
                          >
                            {t("Go!")}
                          </p>
                        </div>
                      </div>
                      <hr className="w-100 clearboth" />

                      <div className="mobilediv">
                        <div className="col-12">
                          <ul className="location-address col-12  ml-auto mr-auto mt-0 p-0">
                            {locationdata[key].coo !== "" ? (
                              <li
                                className="innerpagetitle customCursor width-adjust-class"
                                onClick={() =>
                                  manager_details(locationdata[key].coo.id)
                                }
                              >
                                <span className="left-side-data font-weight-bold">
                                  COO
                                </span>
                                <span className="right-side-date">
                                  {locationdata[key].coo["First Name"]}&nbsp;
                                  {locationdata[key].coo["Last Name"]}
                                </span>
                              </li>
                            ) : null}

                            {locationdata[key].clusterDirector !== "" ? (
                              <li
                                className="innerpagetitle customCursor width-adjust-class"
                                onClick={() =>
                                  manager_details(
                                    locationdata[key].clusterDirector.id
                                  )
                                }
                              >
                                <span className="left-side-data font-weight-bold">
                                  Cluster director
                                </span>
                                <span className="right-side-date">
                                  {
                                    locationdata[key].clusterDirector[
                                      "First Name"
                                    ]
                                  }
                                  &nbsp;
                                  {
                                    locationdata[key].clusterDirector[
                                      "Last Name"
                                    ]
                                  }
                                </span>
                              </li>
                            ) : null}

                            {locationdata[key].site_manager !== ""
                              ? locationdata[key].site_manager.map((item) => (
                                  <li
                                    className="innerpagetitle customCursor width-adjust-class"
                                    onClick={() => manager_details(item.id)}
                                    key={item.id}
                                  >
                                    <span className="left-side-data font-weight-bold">
                                      Site manager
                                    </span>
                                    <span className="right-side-date">
                                      {item["First Name"]}
                                      &nbsp;
                                      {item["Last Name"]}
                                    </span>
                                  </li>
                                ))
                              : null}

                            {locationdata[key].localHr !== "" ? (
                              <li className="innerpagetitle width-adjust-class">
                                <span className="left-side-data font-weight-bold">
                                  Local HR
                                </span>
                                <span className="right-side-data customCursor">
                                  {locationdata[key].localHr.map(
                                    (item, index) => (
                                      <span
                                        key={item.id}
                                        onClick={() => manager_details(item.id)}
                                      >
                                        {index ? ", " : ""}
                                        {item["First Name"]}
                                        &nbsp;
                                        {item["Last Name"]}
                                      </span>
                                    )
                                  )}
                                </span>
                              </li>
                            ) : null}

                            {locationdata[key].localIt !== "" ? (
                              <li className="innerpagetitle width-adjust-class">
                                <span className="left-side-data font-weight-bold">
                                  Local IT
                                </span>
                                <span className="right-side-data customCursor">
                                  {locationdata[key].localIt.map(
                                    (item, index) => (
                                      <span
                                        key={item.id}
                                        onClick={() => manager_details(item.id)}
                                      >
                                        {index ? ", " : ""}
                                        {item["First Name"]}
                                        &nbsp;
                                        {item["Last Name"]}
                                      </span>
                                    )
                                  )}
                                </span>
                              </li>
                            ) : null}
                          </ul>
                          {/* <div>
                                <hr className="w-100 clearboth" />
                          </div> */}
                        </div>
                        <div>
                          {locationdata[key].coo === "" &&
                          locationdata[key].clusterDirector === "" &&
                          locationdata[key].site_manager === "" &&
                          locationdata[key].localHr === "" &&
                          locationdata[key].localIt === "" ? null : (
                            <hr className="w-100 clearboth" />
                          )}
                        </div>

                        <div className="my-2 locationdes">
                          <p className="locationRed iconAlign imgSprite mb-5"></p>{" "}
                          <p className="addressdetail  innerpagetitle">
                            {locationdata[key].compelete_address.address1}{" "}
                            <br />{" "}
                            {locationdata[key].compelete_address.address2}{" "}
                            <br />{" "}
                            {locationdata[key].compelete_address.pin_and_city}{" "}
                            <br />
                            {
                              locationdata[key].compelete_address
                                .compelete_address_country
                            }
                          </p>
                        </div>
                        <div className="locationdes">
                          {locationdata[key].map_latitude != null ? (
                            <MiniMap
                              lats={locationdata[key].map_latitude}
                              long={locationdata[key].map_longitude}
                              mapaddress={locationdata[key].map_details}
                              type="locationdetail"
                            />
                          ) : null}
                        </div>

                        <div className="my-2 col-md-12 buttonwrap locationbutton">
                          <div className="row my-2">
                            <button
                              className={
                                locationdata[key].library_status
                                  ? "btn bg-color"
                                  : "disabledbutton"
                              }
                              onClick={() => {
                                history.push({
                                  pathname: "/library",
                                  state: { location: locationdata[key].title },
                                });
                              }}
                            >
                              <img src={btn2} alt="Library icon" />
                              {t("Library")}
                            </button>
                            <button
                              className={
                                locationdata[key].lunchtip_status
                                  ? "btn bg-color btn2"
                                  : "disabledbutton btn2"
                              }
                              onClick={() => {
                                history.push({
                                  pathname: "/lunchtips",
                                  state: { location: locationdata[key].title },
                                });
                              }}
                            >
                              <img src={btn1} alt="LunchTip Icon" />{" "}
                              {t("Lunchtip")}
                            </button>
                          </div>
                          <div className="row my-2 justify-content-center">
                            <button
                              className={
                                locationdata[key].meetingroom_status
                                  ? "btn bg-color btn2"
                                  : "disabledbutton btn2"
                              }
                              onClick={() => {
                                history.push({
                                  pathname: "/meetingrooms",
                                  state: { location: locationdata[key].title },
                                });
                              }}
                            >
                              <img src={meeting} alt="Library icon" />{" "}
                              {t("Meeting room")}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="desktopdiv">
                        <div className="row">
                          <div className="col-md-12">
                            <ul className="location-address col-md-6 m-auto p-0">
                              {locationdata[key].coo !== "" ? (
                                <li
                                  className="innerpagetitle customCursor width-adjust-class"
                                  onClick={() =>
                                    manager_details(locationdata[key].coo.id)
                                  }
                                >
                                  <span className="left-side-data font-weight-bold">
                                    COO
                                  </span>
                                  <span className="right-side-date">
                                    {locationdata[key].coo["First Name"]}&nbsp;
                                    {locationdata[key].coo["Last Name"]}
                                  </span>
                                </li>
                              ) : null}

                              {locationdata[key].clusterDirector !== "" ? (
                                <li
                                  className="innerpagetitle customCursor width-adjust-class"
                                  onClick={() =>
                                    manager_details(
                                      locationdata[key].clusterDirector.id
                                    )
                                  }
                                >
                                  <span className="left-side-data font-weight-bold">
                                    Cluster director
                                  </span>
                                  <span className="right-side-date">
                                    {
                                      locationdata[key].clusterDirector[
                                        "First Name"
                                      ]
                                    }
                                    &nbsp;
                                    {
                                      locationdata[key].clusterDirector[
                                        "Last Name"
                                      ]
                                    }
                                  </span>
                                </li>
                              ) : null}

                              {locationdata[key].site_manager !== ""
                                ? locationdata[key].site_manager.map((item) => (
                                    <li
                                      className="innerpagetitle customCursor width-adjust-class"
                                      onClick={() => manager_details(item.id)}
                                      key={item.id}
                                    >
                                      <span className="left-side-data font-weight-bold">
                                        Site manager
                                      </span>
                                      <span className="right-side-date">
                                        {item["First Name"]}
                                        &nbsp;
                                        {item["Last Name"]}
                                      </span>
                                    </li>
                                  ))
                                : null}

                              {locationdata[key].localHr !== "" ? (
                                <li className="innerpagetitle width-adjust-class">
                                  <span className="left-side-data font-weight-bold">
                                    Local HR
                                  </span>
                                  <span className="right-side-data customCursor">
                                    {locationdata[key].localHr.map(
                                      (item, index) => (
                                        <span
                                          key={item.id}
                                          onClick={() =>
                                            manager_details(item.id)
                                          }
                                        >
                                          {index ? ", " : ""}
                                          {item["First Name"]}
                                          &nbsp;
                                          {item["Last Name"]}
                                        </span>
                                      )
                                    )}
                                  </span>
                                </li>
                              ) : null}

                              {locationdata[key].localIt !== "" ? (
                                <li className="innerpagetitle width-adjust-class">
                                  <span className="left-side-data font-weight-bold">
                                    Local IT
                                  </span>
                                  <span className="right-side-data customCursor">
                                    {locationdata[key].localIt.map(
                                      (item, index) => (
                                        <span
                                          key={item.id}
                                          onClick={() =>
                                            manager_details(item.id)
                                          }
                                        >
                                          {index ? ", " : ""}
                                          {item["First Name"]}
                                          &nbsp;
                                          {item["Last Name"]}
                                        </span>
                                      )
                                    )}
                                  </span>
                                </li>
                              ) : null}
                            </ul>
                            <div>
                              {locationdata[key].coo === "" &&
                              locationdata[key].clusterDirector === "" &&
                              locationdata[key].site_manager === "" &&
                              locationdata[key].localHr === "" &&
                              locationdata[key].localIt === "" ? null : (
                                <hr className="w-100 clearboth" />
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 my-2 locationdes">
                            <p className="locationRed iconAlign imgSprite mb-5"></p>{" "}
                            <p className="addressdetail  innerpagetitle">
                              {locationdata[key].compelete_address.address1}{" "}
                              <br />{" "}
                              {locationdata[key].compelete_address.address2}{" "}
                              <br />{" "}
                              {locationdata[key].compelete_address.pin_and_city}{" "}
                              <br />
                              {
                                locationdata[key].compelete_address
                                  .compelete_address_country
                              }
                            </p>
                          </div>

                          <div className="col-md-8">
                            <div className="locationdes">
                              {locationdata[key].map_latitude != null ? (
                                <MiniMap
                                  lats={locationdata[key].map_latitude}
                                  long={locationdata[key].map_longitude}
                                  mapaddress={locationdata[key].map_details}
                                  type="locationdetail"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="my-2 col-md-12 buttonwrap d-flex justify-content-center text-center">
                          <button
                            className={
                              locationdata[key].library_status
                                ? "btn bg-color  actionButton-loc"
                                : "disabledbutton actionButton-loc"
                            }
                            onClick={() => {
                              history.push({
                                pathname: "/library",
                                state: { location: locationdata[key].title },
                              });
                            }}
                          >
                            <img src={btn2} alt="Library icon" />
                            {t("Library")}
                          </button>
                          <button
                            className={
                              locationdata[key].lunchtip_status
                                ? "btn bg-color actionButton-loc"
                                : "disabledbutton actionButton-loc"
                            }
                            onClick={() => {
                              history.push({
                                pathname: "/lunchtips",
                                state: { location: locationdata[key].title },
                              });
                            }}
                          >
                            <img src={btn1} alt="LunchTip Icon" />{" "}
                            {t("Lunchtip")}
                          </button>
                          <button
                            className={
                              locationdata[key].meetingroom_status
                                ? "btn bg-color  actionButton-loc"
                                : "disabledbutton actionButton-loc"
                            }
                            onClick={() => {
                              history.push({
                                pathname: "/meetingrooms",
                                state: { location: locationdata[key].title },
                              });
                            }}
                          >
                            <img src={meeting} alt="Library icon" />{" "}
                            {t("Meeting room")}
                          </button>
                        </div>
                      </div>

                      {locationdata[key].video.length > 0 ? (
                        <div className="my-2">
                          <br></br>
                          <div id="frame" className="col-12 p-0 ">
                            <iframe
                              title="youtube Video"
                              width="100%"
                              src={locationdata[key].video}
                              allowFullScreen="allowfullscreen"
                            ></iframe>
                          </div>
                        </div>
                      ) : null}

                      <div
                        className="col-md-12 Richtexterditor clearboth"
                        dangerouslySetInnerHTML={createMarkup(
                          locationdata[key].location_description
                        )}
                      />
                      {/* <p className="card-text"></p> */}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationDetails;
