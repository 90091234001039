import React, { useState, useEffect } from 'react';
import { poll } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import Slider from "../../Utilities/Slider/Slider";
import QuestionPopup from './QuestionPopup';
import { t } from '../../Translation/Translation';
import '../../Static/css/common.css';
import FullScreenImage from "../../Utilities/FullScreenImage/FullScreenImage";
import Charts from './Charts';
import BackButtonComponent from '../../Utilities/BackButtonComponent'
import BackButton from "../../Utilities/BackButton";

import previewFn from '../../Common/Preview';

export default function PollsDetails({ updatesEntityId, updateslikes, Results, UpdateStatus, backButton }) {
  const [pollslist, setpollslist] = useState('')
  const history = useHistory();
  const [sliderData, setsliderData] = useState("");
  const [barchart, setbarcharts] = useState(Results);
  const [Questions, setQuestions] = useState(false);
  const [BannerTime, setBannerTime] = useState();
  const [fullscreenState, setfullscreenState] = useState(false);
  const [imageSrc, setimageSrc] = useState("");
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  const [previewBack, setpreviewBack] = useState(false);
  let params = useParams();

  useEffect(() => {
    let currentpath = window.location.href;
    if (currentpath.indexOf("preview") > 0){
      setpreviewBack(true);
    }
  }, [])

  useEffect(() => {
    if (urlState === false && updatesEntityId !== undefined) {
      setbackbuttonUrl('/polls/' + updatesEntityId.id);
      seturlState(true);
    }
  }, [updatesEntityId])
  useEffect(() => {
    setbarcharts(false);
    //  check updatesEntityId undifined or not
    if (updatesEntityId) {
      const get_id = localStorage.getItem("id");
      let param = previewFn(params)
      let data = {
        'user_id': get_id,
        'entry_id': updatesEntityId.id,
        'type': 'view',
        'param': param
      }
      APICALL.service(poll, "POST", data)
        .then(result => {
          if (result !== undefined || result !== '' || result !== null) {
            try {
              setBannerTime(result[updatesEntityId.id].banners_time);
              let deviceMode = localStorage.getItem("DeviceMode");
              if(deviceMode === "Desktop"){
                _constructSliderData(result[updatesEntityId.id].banner_images);
              }else{
                _constructSliderData(result[updatesEntityId.id].mobile_banner_images);
              }
              setpollslist(result)
            } catch (e) {
              console.error(e);
            }
          }
        })
    }
  }, [UpdateStatus, updatesEntityId]);

  const handleFormVisibility = (e) => {
    setbarcharts(true)
  }
  //Function to construct data for slideshow
  const _constructSliderData = (data) => {
    const sliders = [];
    data.length > 0 && data.forEach((val, i) => {
      sliders[i] = { 'original': val.image, 'thumbnail': val.image };
    })
    setsliderData(sliders);

  }
  const handleQuestions = () => {
    setQuestions(true);
  }
  function QuestionState() {
    setQuestions(false);
  }

  const isPollActive = (startDate, endDate) => {
    let pollStartdate = new Date(startDate);
    let pollenddate = new Date(endDate);
    let currentDate = new Date();
    // let temp;
    // let current = moment(new Date()).format('YYYY-MM-DD')
    // let curenttTime = moment(new Date()).format('HH:mm')
    // var currentDate = current + "T" + curenttTime + ":00Z";
    if (currentDate >= pollStartdate && currentDate <= pollenddate) {
      return true
    }
    return false;
  }
  //converting  UTC to local time 
  function convertUTCDateToLocalDate(date) {
    let d = new Date(date);
    return d.toString();
  }
  //Viewing Images in Full Screen
  function EnableFullScreenImage(ImageData) {
    setimageSrc(ImageData);
    setfullscreenState(true);
  }
  //Disable Images in Full Screen
  function DisableFullScreenImage() {
    setimageSrc("");
    setfullscreenState(false);
  }

  // updating pollslist setState values
  function updateField(e) {
    updateslikes(e);
    Object.keys(pollslist).map((key) => {
      var list = { ...pollslist }
      list[key].user_response_status = pollslist[key].user_response_status === true ? false : true
      setpollslist(list)
    })
  }
  //To return HTML from string containing HTML tags
  function createMarkup(data) {
    if(data){
      data = data.replaceAll("<p></p>", "<br />");
    }
    return { __html: data };
  }
  
  // back button funtionality
  function historyBack() {
    let response = BackButton("/polls");
    if (response === false) {
      backButton(false);
      history.push('/polls');
    }
    else {
      backButton(false);
      history.push(response);
    }

  }
  const backButtons = () => {
    setbarcharts(false)
  }

  return (
    fullscreenState ? (
      <FullScreenImage
        imageSrc={imageSrc}
        DisableFullScreenImage={DisableFullScreenImage}
      />
    ) : (
      barchart ?
        <Charts updatesEntityId={updatesEntityId.id} backButtons={backButtons} />
        :
        <div className="container customContainer">

          {
            pollslist !== undefined && pollslist.status !== '404' &&
            Object.keys(pollslist).map((key) => (
              <div className="" key={key}>
                {
                  localStorage.getItem('DeviceMode') !== "Desktop" ? 
                   <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4" key={pollslist.id}>
                      {previewBack ? null : <p className="browserback" onClick={historyBack}>back</p>}
                   {backbuttonUrl && <BackButtonComponent backButtonLink = {backbuttonUrl}/>}
                   <h1 className="pagetitle textcolor1 text-center">{t('Survey')}</h1>
                 </div>:null
                }

                {(sliderData.length > 0) &&
                  <Slider
                    history={history}
                    bannerTime={BannerTime ? BannerTime : '5'}
                    sliderData={sliderData}
                    EnableFullScreenImage={EnableFullScreenImage}
                  />
                }
                <div className="col-12 p-0 mb-3 mt-4">
                  <div className="row">
                    <div className="col-10">
                      <h4 className="card-title">{pollslist[key].title ? pollslist[key].title : null}</h4>
                    </div>
                    <div className="col-2 text-right"> <p className="surveygrey surveydetailicon imgSprite mb-2"></p></div>
                    <div className="col-md-12 col-12 text-right" style={{ cursor: 'pointer' }}>
                      {pollslist[key].question && pollslist[key].options !== (null || "" || undefined) ?
                        <p className={(pollslist[key].user_response_status === false &&
                          (isPollActive(pollslist[key].start_date, pollslist[key].end_date) === true))
                          ? "textcolor1 mb-0 pt-3"
                          : "disableLink mb-0"} onClick={handleQuestions}>
                          {t('Vote')}
                        </p> : null}
                    </div>
                  </div>
                </div>


                <div className="mobilediv">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-6 pl-0 startdate">
                        <p className="px-2 textcolor1 pt-3 mb-0">{t('Start date')}</p>
                        <p className="px-2 py-2 mb-0">
                          {pollslist[key].start_date ?
                            moment(new Date(pollslist[key].start_date)).format('DD/MM/YYYY') : null}
                        </p>
                      </div>
                      <div className="col-6 pr-0 enddate">
                        <p className="px-2 textcolor1 pt-3 mb-0">
                          {t('End date')}</p>
                        <p className="px-2 py-2 mb-0">
                          {pollslist[key].end_date ?
                            moment(convertUTCDateToLocalDate(pollslist[key].end_date)).format('DD/MM/YYYY') : null}
                        </p>
                      </div>
                      <div className="col-6 pl-0 startdate">
                        <p className="px-2 textcolor1 pt-3 mb-0">
                          {t('Start time')}</p>
                        <p className="px-2 py-2 mb-0">
                          {pollslist[key].start_date ?
                            moment(convertUTCDateToLocalDate(pollslist[key].start_date)).format('HH:mm') : null}
                        </p>
                      </div>
                      <div className="col-6 pr-0 enddate">
                        <p className="px-2 textcolor1 pt-3 mb-0">
                          {t('End time')}</p>
                        <p className="px-2 py-2 mb-0">
                          {pollslist[key].end_date ?
                            moment(convertUTCDateToLocalDate(pollslist[key].end_date)).format('HH:mm')
                            :
                            null}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="desktopdiv">

                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-3 pl-0 startdate">
                        <p className="px-2 textcolor1 pt-3 mb-0">{t('Start date')}</p>
                        <p className="px-2 py-2 mb-0">
                          {pollslist[key].start_date ?
                            moment(new Date(pollslist[key].start_date)).format('DD/MM/YYYY') : null}
                        </p>
                      </div>
                      <div className="col-3 pr-0 end-date-2" >
                        <p className="px-2 textcolor1 pt-3 mb-0">
                          {t('End date')}</p>
                        <p className="px-2 py-2 mb-0">
                          {pollslist[key].end_date ?
                            moment(convertUTCDateToLocalDate(pollslist[key].end_date)).format('DD/MM/YYYY') : null}
                        </p>
                      </div>
                      <div className="col-3 pl-0 startdate">
                        <p className="px-2 textcolor1 pt-3 mb-0">
                          {t('Start time')}</p>
                        <p className="px-2 py-2 mb-0">
                          {pollslist[key].start_date ?
                            moment(convertUTCDateToLocalDate(pollslist[key].start_date)).format('HH:mm') : null}
                        </p>
                      </div>
                      <div className="col-3 pr-0 enddate">
                        <p className="px-2 textcolor1 pt-3 mb-0">
                          {t('End time')}</p>
                        <p className="px-2 py-2 mb-0">
                          {pollslist[key].end_date ?
                            moment(convertUTCDateToLocalDate(pollslist[key].end_date)).format('HH:mm')
                            :
                            null}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 p-0 my-3 Richtexterditor"
                  dangerouslySetInnerHTML={createMarkup(pollslist[key].content)}
                />
                <div>{pollslist[key].result_status === true ?
                  <button className="bg-color btn result-btn mb-4 "
                    onClick={handleFormVisibility}>{t('Result')}
                  </button>
                  : null}
                </div>
                {/* { barchart === true ?
                  history.push({
                    pathname: "/servey-report/" + updatesEntityId.id,
                  })
                  : null} */}

                { Questions ?
                  <QuestionPopup
                    data={pollslist}
                    show={true}
                    entry_id={updatesEntityId.id}
                    updateField={updateField}
                    type={'details'}
                    startDate={pollslist[key].start_date}
                    endDate={pollslist[key].end_date}
                    QuestionState={QuestionState}
                  ></QuestionPopup>
                  : null
                }
              </div>
            ))
          }
          {pollslist !== null && pollslist.lenght === '0' && pollslist.status === '404' && <p> {t('No Description')}</p>}

        </div>
    )
  )
}