// eslint-disable-next-line no-unused-vars
import React, { useState, Fragment, useRef, useEffect } from "react";
import { t } from "../../Translation/Translation";
import { IdeaboxUrl } from "../../Services/ApiEndPoints";
import { APICALL as AXIOS } from "../../Services/AxiosServices";
import ImageUploading from "react-images-uploading";
import Popup from "../../Utilities/Popup/modelPopup";
import { useHistory } from "react-router-dom";
import Files from "react-files";
// import { auto } from "@popperjs/core";
import "./ideabox.css";
import BackButtonComponent from "../../Utilities/BackButtonComponent";
import BackButton from "../../Utilities/BackButton";

export default function Ideabox(props) {
  // defining states for title and description
  const [idea, setIdea] = useState("");
  const [ideaDescription, setIdeaDescription] = useState("");
  // defining states for dynamic field urls
  const [url, setUrl] = useState("");
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [images, setImages] = React.useState([]);
  const [popupstate, setPopupState] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  const [documents, setDocuments] = useState([]);
  const history = useHistory();
  const ref = useRef();
  const [urlState, seturlState] = useState(false);
  const formData = new FormData();

  // images change handler
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  // file change handler
  const onFilesChange = (files) => {
    setDocuments(files);
  };

  // file removing
  const filesRemoveOne = (file) => {
    ref.current.removeFile(file);
  };

  //   handling input change event for url
  const handleInputChange = (event) => {
    setUrl(event.target.value);
  };

  //   handling input change event for (idea and description)
  const handleInput = (event) => {
    if (event.target.name === "idea") {
      setIdea(event.target.value);
    } else {
      setIdeaDescription(event.target.value);
    }
  };

  //   handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    createFormData();

    AXIOS.service(IdeaboxUrl, "POST", formData)
      .then((result) => {
        setMessage(result.message);
        setTitle(t("Success"));
        setPopupState(true);
        setError(false);
        clearStates();
      })
      .catch((error) => {
        setMessage(t("Error adding idea, please try again"));
        setTitle(t("Alert"));
        setError(true);
        setPopupState(true);
      });
  };

  //   after responce 200 clearing state
  const clearStates = () => {
    setIdea("");
    setIdeaDescription("");
    setImages([]);
    ref.current.removeFiles();
    setUrl("");
  };
  //close popup
  const closePopup = () => {
    setMessage("");
  };
  // constructingform data
  const createFormData = () => {
    formData.append("user_id", localStorage.getItem("id"));
    formData.append("title", idea);
    formData.append("description", ideaDescription);
    appendImageToForm();
    appendDocumentToForm();
    appendUrlToForm();
    // printing what is their in form
    // for (var key of formData.entries()) {
    //     console.log("formdata", key[0] + ', ' + key[1]);
    // }
  };
  // appending images to form from state
  const appendImageToForm = () => {
    Object.keys(images).map((key) => {
      formData.append("ideaboximage[" + key + "]", images[key].file);
    });
  };
  // appending documents to form
  const appendDocumentToForm = () => {
    Object.keys(documents).map((key) => {
      formData.append("documents[" + key + "]", documents[key]);
    });
  };
  // appending videos to form from state
  const appendUrlToForm = () => {
    formData.append("videourl", url);
  };
  /***
   * Store url in session storage for back button functionality
   */
  useEffect(() => {
    if (urlState === false) {
      setbackbuttonUrl("/ideabox");
      seturlState(true);
    }
  }, []);
  
  //Remove the last item from array and push the url for back button
  function historyBack() {
    let response = BackButton();
    history.push(response);
  }

  return (
    <div
      className="container customContainer"
      style={{ overflowX: "hidden", overflowY: "auto", height: "100vh" }}
    >
      <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4">
        <p className="browserback" onClick={historyBack}>
          back
        </p>
        {backbuttonUrl && (
          <BackButtonComponent backButtonLink={backbuttonUrl} />
        )}
        <h1 className="pagetitle textcolor1 text-center">{t("Ideabox")}</h1>
      </div>
      <br></br>
      <div className="post">
        <form
          className="fieldsWrapp ideaboxform"
          id="form"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="form-group  addtipssearch">
            <label className="textcolor1 addlunchlable" htmlFor="idea">
              {t("Title")}
            </label>
            <input
              className="form-control"
              name="idea"
              id="idea"
              value={idea}
              onChange={(event) => handleInput(event)}
              placeholder={t("Add an idea")}
              required
            />
          </div>
          {popupstate === true && (
            <Popup
              show={message !== "" ? true : false}
              onHide={closePopup}
              size={"lg"}
              title={title}
              body={message}
            />
          )}
          <div className="form-group">
            <label className="textcolor1 addlunchlable" htmlFor="description">
              {t("Description")}
            </label>
            <textarea
              name="description"
              type="text"
              id="description"
              //rows="3"
              value={ideaDescription}
              onChange={(event) => handleInput(event)}
              className="form-control"
              placeholder={t("Add description")}
            />
          </div>
          <div className="form-group">
            <label className="textcolor1 addlunchlable" htmlFor="url">
              {t("Upload an video URL")}
            </label>
            <input
              type="text"
              className="form-control"
              id="url"
              name="url"
              placeholder={t("Add an URL")}
              value={url}
              onChange={(event) => handleInputChange(event)}
            />
          </div>
          <div className="form-group  addtipssearch">
            <div className="form-row">
              <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                // maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  // onImageRemoveAll,
                  // onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="col-md-12 pl-0">
                    <div
                      className="btn btn-light"
                      style={{ textAlign: "left", paddingLeft: "5px" }}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      {t("+ Add image")}
                    </div>
                    &nbsp;
                    {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                    {imageList.map((image, index) => (
                      // <div className="row">
                      <div key={index} className="col-md-12">
                        {/* <img src={image["data_url"]} alt="" width="100" /> */}
                        <div className="row col-md-12 m-auto px-0">
                          <p
                            className="col-md-12 px-0"
                            style={{ wordBreak: "break-word" }}
                          >
                            {image.file.name}
                            <span
                              onClick={() => onImageRemove(index)}
                              className="btn btn-link"
                            >
                              Remove
                            </span>
                          </p>
                          {/* <div className="image-item__btn-wrapper"> */}
                          {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                          {/* </div> */}
                        </div>
                      </div>
                      // </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
            </div>
            <div className="form-group  addtipssearch mb-0">
              <div className="form-row">
                <Files
                  // className='files-dropzone'
                  ref={ref}
                  onChange={onFilesChange}
                  // onError={this.onFilesError}
                  accepts={[".pdf"]}
                  multiple
                  // maxFiles={3}
                  // maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                >
                  <div
                    className="btn btn-light col-md-12"
                    style={{ textAlign: "left", paddingLeft: "5px" }}
                  >
                    {t("+ Add document")}
                  </div>
                </Files>
              </div>
            </div>
            {documents.length > 0 ? (
              <div className="files-list mb-0">
                <div>
                  {documents.map((file) => (
                    <div className="row col-md-12 m-auto px-0" key={file.id}>
                      <p
                        className="col-md-12"
                        style={{ wordBreak: "break-word" }}
                      >
                        {file.name}
                        <span
                          onClick={() => filesRemoveOne(file)}
                          className="btn btn-group btn-link"
                        >
                          Remove
                        </span>
                      </p>
                      {/* <div className='files-list-item-content-item files-list-item-content-item-2'>{file.sizeReadable}</div> */}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-12 col-12 pl-0 px-0 text-center votePopup mt-3 mb-4">
            <button
              type="submit"
              className="btn  m-0 rateSave  bg-color font-weight-bold"
            >
              {t("Submit your idea")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
