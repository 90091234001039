export const ENV_URL = process.env.REACT_APP_BACKEND_URL;
export const REGEX_URL = 'api/v1/';
export const BASE_URL = ENV_URL + REGEX_URL;

export const LogoutLink = '/admin/logout/';
export const LoginUrl = BASE_URL + "logins";
export const NewsUrl = BASE_URL + "news";
export const EventUrl = BASE_URL + "events";
export const Profiles = BASE_URL + "profiles";
export const UserProfile = BASE_URL + "profiles/";
export const Banner = BASE_URL + "banners";
export const Homes = BASE_URL + "homes";
export const Userhobbies = BASE_URL + "hobbies";
export const LibraryUrl = BASE_URL + "libraries";
export const ActiveLanguages = BASE_URL + "activelanguages";
export const AboutDisclaimers = BASE_URL + "aboutdisclaimers";
export const TermsConditions = BASE_URL + "termsconditions";
export const Comments = BASE_URL + "comments";
export const DataTranslations = BASE_URL + "translations";
export const Locations = BASE_URL + "locations";
export const users = BASE_URL + "users";
export const LunchTipsUrl = BASE_URL + "lunchtips";
export const ActiveUsers = BASE_URL + "activeusers"
export const NavigationItems = BASE_URL + "navigations"
export const poll = BASE_URL + "polls";
export const LunchTipsDetailUrl = BASE_URL + "lunchtipdetails";
export const AddRatingUrl = BASE_URL + "ratings";
export const likes = BASE_URL + "likes";
export const Notifications = BASE_URL + "notifications"
export const PAGINATE_BY = 10;
export const FoodTypeUrl = BASE_URL + "foodtypes";
export const Eshop = BASE_URL + "eshops";
export const Embraces = BASE_URL + "embraces";
export const Discount = BASE_URL + "discounts";
export const AllUsers = BASE_URL + "allusers";
export const SocialMediaLink = BASE_URL + "socialmediaurls";
export const Vacancy = BASE_URL + "vacancies";
export const IdeaboxUrl = BASE_URL + "ideaboxs";
export const ReactionsList = BASE_URL + "reactions";
export const ICSfile = BASE_URL + "icsevents";
export const toolBoxEndpoint = BASE_URL + "toolboxs";
export const MeetingsList = BASE_URL + "meetings";
export const SignatureDataFetch = BASE_URL + "signatures"
export const Common = BASE_URL + "commons";
    //follow the below example to add your url endpoints

// EXAMPLE : export const getUserDetails = BASE_URL + 'account/get-user-details';